import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormComponentProps, ManageDatePickerProps, ManageSwitchInputProps, ManageTextProps } from '@Hooks';
import { Box, Grid } from '@chakra-ui/react';
import SelectInput from '@Components/SelectInput';
import TextInput from '@Components/TextInput';
import Switch from '@Components/Switch';
import { useGetPreferredGendersRequestQuery, useGetGendersRequestQuery } from '@Redux/services/LookupApi';
import DateTimeInput from '@Components/DateTimeInput';
import { formatPhoneNumber, removeSpecialCharacters } from '@Pages/RegistrationDetails/helpers';

const StudentDetails: React.FC<FormComponentProps> = ({
    manageFieldPropFactory,
    textFieldPropFactory,
    selectFieldPropFactory,
    switchFieldPropFactory,
}) => {
    const [t] = useTranslation();

    const { data: { data: genders = [] } = {} } = useGetPreferredGendersRequestQuery({ searchText: '' });
    const { data: { data: gendersAtBirth = [] } = {} } = useGetGendersRequestQuery({ searchText: '' });

    return (
        <>
            <Grid
                column={{ md: 3, sm: 1 }}
                columnGap={10}
                gridAutoFlow='dense'
                templateColumns={{
                    base: 'repeat(1, 1fr)',
                    md: '1fr 1fr 1fr',
                }}
                rowGap={4}
                templateRows='auto'
                mb={6}
            >
                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.aboutStudent.studentDetails.firstName'), [
                        'childInformation',
                        'legalFirstName',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['childInformation', 'legalFirstName'],
                        'onChangeText',
                        'value',
                        removeSpecialCharacters
                    )}
                    labelProps={{ flexBasis: '55%' }}
                />
                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.aboutStudent.studentDetails.middleName'), [
                        'childInformation',
                        'middleName',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['childInformation', 'middleName'],
                        'onChangeText',
                        'value',
                        removeSpecialCharacters
                    )}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.aboutStudent.studentDetails.lastName'), [
                        'childInformation',
                        'legalSurname',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['childInformation', 'legalSurname'],
                        'onChangeText',
                        'value',
                        removeSpecialCharacters
                    )}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.aboutStudent.studentDetails.preferredFirstName'), [
                        'childInformation',
                        'preferredFirstName',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['childInformation', 'preferredFirstName'],
                        'onChangeText',
                        'value',
                        removeSpecialCharacters
                    )}
                    labelProps={{ flexBasis: '55%' }}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.aboutStudent.studentDetails.preferredLastName'), [
                        'childInformation',
                        'preferredSurname',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['childInformation', 'preferredSurname'],
                        'onChangeText',
                        'value',
                        removeSpecialCharacters
                    )}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.aboutStudent.studentDetails.primaryPhoneNumber'), [
                        'childInformation',
                        'phoneNumber',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['childInformation', 'phoneNumber'],
                        'onChangeText',
                        'value',
                        formatPhoneNumber
                    )}
                />

                <DateTimeInput
                    {...textFieldPropFactory(t('RegistrationDetails.aboutStudent.studentDetails.dateOfBirth'), [
                        'childInformation',
                        'dateOfBirth',
                    ])}
                    {...manageFieldPropFactory<ManageDatePickerProps>(
                        ['childInformation', 'dateOfBirth'],
                        'onDateChange',
                        'date'
                    )}
                    size='md'
                    labelProps={{ flexBasis: '55%' }}
                />

                <SelectInput
                    {...selectFieldPropFactory(t('RegistrationDetails.aboutStudent.studentDetails.genderAtBirth'), [
                        'childInformation',
                        'gender',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['childInformation', 'gender'],
                        'onChangeValue',
                        'value'
                    )}
                    options={gendersAtBirth}
                />

                <SelectInput
                    {...selectFieldPropFactory(t('RegistrationDetails.aboutStudent.studentDetails.gender'), [
                        'childInformation',
                        'preferredGender',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['childInformation', 'preferredGender'],
                        'onChangeValue',
                        'value'
                    )}
                    options={genders}
                />
            </Grid>
            <Box pb={4}>
                <Switch
                    {...switchFieldPropFactory(t('RegistrationDetails.aboutStudent.studentDetails.sharePhoneNumber'), [
                        'childInformation',
                        'isPhoneNumberUnlisted',
                    ])}
                    {...manageFieldPropFactory<ManageSwitchInputProps>(
                        ['childInformation', 'isPhoneNumberUnlisted'],
                        'onChangeSwitch',
                        'isChecked'
                    )}
                    col={1}
                />
            </Box>
            <Box pb={4}>
                <Switch
                    {...switchFieldPropFactory(
                        t('RegistrationDetails.aboutStudent.studentDetails.internationalStudent'),
                        ['childInformation', 'isInternational']
                    )}
                    {...manageFieldPropFactory<ManageSwitchInputProps>(
                        ['childInformation', 'isInternational'],
                        'onChangeSwitch',
                        'isChecked'
                    )}
                    col={1}
                />
            </Box>
        </>
    );
};

export default StudentDetails;
