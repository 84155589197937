import FormHeader from '@Components/FormHeader';
import React from 'react';
import InnerFormHeader from '../InnerFormHeader';
import { FormComponentProps, ManageTextProps } from '@Hooks';
import { useTranslation } from 'react-i18next';
import { Grid } from '@chakra-ui/react';
import SelectInput from '@Components/SelectInput';
import TextInput from '@Components/TextInput';
import { useGetRelationshipsRequestQuery } from '@Redux/services/LookupApi';
import SvgGuardianContact from '@Icon/GuardianContact';
import { formatPhoneNumber } from '@Pages/RegistrationDetails/helpers';
import { ParentPosition } from 'src/constants';
import { GuardianDetails } from '@Redux/services/LinkingRequest/types';
import { PartialDeep } from 'type-fest';

const GuardianContactDetails: React.FC<FormComponentProps> = ({
    id,
    manageFieldPropFactory,
    selectFieldPropFactory,
    textFieldPropFactory,
}) => {
    const [t] = useTranslation();

    //get relationships from redux
    const { data: { data: relationships = [] } = {} } = useGetRelationshipsRequestQuery({ searchText: '' });
    const positions = [
        { key: ParentPosition.Parent1, value: 'Parent1' },
        { key: ParentPosition.Parent2, value: 'Parent2' },
        { key: ParentPosition.Parent3, value: 'Parent3' },
        { key: ParentPosition.Parent4, value: 'Parent4' },
    ];

    return (
        <FormHeader
            id={id}
            header={
                <InnerFormHeader
                    divider={true}
                    title={t('LinkingRequestDetails.guardianContactDetails.label')}
                    icon={<SvgGuardianContact />}
                />
            }
            label={''}
        >
            <Grid
                column={{ md: 2, sm: 1 }}
                columnGap={10}
                gridAutoFlow='dense'
                templateColumns={{
                    base: 'repeat(1, 1fr)',
                    md: 'repeat(2, 1fr)',
                }}
                rowGap={4}
                templateRows='auto'
                mb={6}
            >
                <SelectInput
                    {...selectFieldPropFactory(t('LinkingRequestDetails.parentPosition'), ['parentPosition'])}
                    {...manageFieldPropFactory<ManageTextProps>(['parentPosition'], 'onChangeValue', 'value')}
                    options={positions}
                />

                <SelectInput
                    {...selectFieldPropFactory(t('LinkingRequestDetails.relationshipToStudent'), [
                        'relationshipToStudent',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(['relationshipToStudent'], 'onChangeValue', 'value')}
                    options={relationships}
                />

                <TextInput
                    {...textFieldPropFactory(t('LinkingRequestDetails.guardianContactDetails.firstName'), [
                        'guardianDetails',
                        'firstName',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['guardianDetails', 'firstName'],
                        'onChangeText',
                        'value'
                    )}
                    isLocked={true}
                />

                <TextInput
                    {...textFieldPropFactory(t('LinkingRequestDetails.guardianContactDetails.lastName'), [
                        'guardianDetails',
                        'lastName',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['guardianDetails', 'lastName'],
                        'onChangeText',
                        'value'
                    )}
                    isLocked={true}
                />

                <TextInput
                    {...textFieldPropFactory(t('LinkingRequestDetails.guardianContactDetails.primaryPhoneNumber'), [
                        'guardianDetails',
                        'primaryPhoneNumber',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['guardianDetails', 'primaryPhoneNumber'],
                        'onChangeText',
                        'value',
                        formatPhoneNumber
                    )}
                    isLocked={true}
                />

                <TextInput
                    {...textFieldPropFactory(t('LinkingRequestDetails.guardianContactDetails.mobilePhoneNumber'), [
                        'guardianDetails',
                        'mobilePhoneNumber',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['guardianDetails', 'mobilePhoneNumber'],
                        'onChangeText',
                        'value',
                        formatPhoneNumber
                    )}
                    isLocked={true}
                />

                <TextInput
                    {...textFieldPropFactory(t('LinkingRequestDetails.guardianContactDetails.workPhoneNumber'), [
                        'guardianDetails',
                        'workPhoneNumber',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['guardianDetails', 'workPhoneNumber'],
                        'onChangeText',
                        'value',
                        formatPhoneNumber
                    )}
                    isLocked={true}
                />
                <TextInput
                    {...textFieldPropFactory(t('LinkingRequestDetails.guardianContactDetails.workExtention'), [
                        'guardianDetails',
                        'workExtension',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['guardianDetails', 'workExtension'],
                        'onChangeText',
                        'value',
                        formatPhoneNumber
                    )}
                    isLocked={true}
                />
                <TextInput
                    {...textFieldPropFactory(t('LinkingRequestDetails.guardianContactDetails.emailAddress'), [
                        'guardianDetails',
                        'email',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(['guardianDetails', 'email'], 'onChangeText', 'value')}
                    col={0}
                    isLocked={true}
                />
            </Grid>
        </FormHeader>
    );
};

export default GuardianContactDetails;
