import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormComponentProps, ManageSwitchInputProps, ManageTextProps } from '@Hooks';
import { Box, Grid } from '@chakra-ui/react';
import SelectInput from '@Components/SelectInput';
import TextInput from '@Components/TextInput';
import { BasicLookup } from '@Redux/services/LookupApi/types';
import Switch from '@Components/Switch';
import { formatPostalCode, removeSpecialCharacters } from '@Pages/RegistrationDetails/helpers';
import { RegistrationInformation } from '@Redux/services/Registration/types';

interface HomeAddressDetailsProps extends FormComponentProps {
    cities: BasicLookup[];
    provinces: BasicLookup[];
    countries: BasicLookup[];
    registrationInformation: RegistrationInformation;
}

const HomeAddressDetails: React.FC<HomeAddressDetailsProps> = ({
    manageFieldPropFactory,
    selectFieldPropFactory,
    textFieldPropFactory,
    switchFieldPropFactory,
    cities,
    provinces,
    countries,
    registrationInformation,
}) => {
    const [t] = useTranslation();

    return (
        <>
            <Box pb={4}>
                <Switch
                    {...switchFieldPropFactory(t('RegistrationDetails.homeAddress.homeAddress.IsUnknown'), [
                        'addressInformation',
                        'isChildAddressUnknown',
                    ])}
                    {...manageFieldPropFactory<ManageSwitchInputProps>(
                        ['addressInformation', 'isChildAddressUnknown'],
                        'onChangeSwitch',
                        'isChecked'
                    )}
                    col={1}
                />
                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.homeAddress.homeAddress.streetNumber'), [
                        'addressInformation',
                        'childAddress',
                        'streetName',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['addressInformation', 'childAddress', 'streetName'],
                        'onChangeText',
                        'value',
                        removeSpecialCharacters
                    )}
                    col={1}
                />
            </Box>
            <Grid
                column={{ md: 2, sm: 1 }}
                columnGap={10}
                gridAutoFlow='dense'
                templateColumns={{
                    base: 'repeat(1, 1fr)',
                    md: 'repeat(2, 1fr)',
                }}
                rowGap={4}
                templateRows='auto'
                mb={6}
            >
                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.homeAddress.homeAddress.apartmentNumber'), [
                        'addressInformation',
                        'childAddress',
                        'apartmentNumber',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['addressInformation', 'childAddress', 'apartmentNumber'],
                        'onChangeText',
                        'value',
                        removeSpecialCharacters
                    )}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.homeAddress.homeAddress.routeNumber'), [
                        'addressInformation',
                        'childAddress',
                        'rrNumber',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['addressInformation', 'childAddress', 'rrNumber'],
                        'onChangeText',
                        'value',
                        removeSpecialCharacters
                    )}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.homeAddress.homeAddress.postalCode'), [
                        'addressInformation',
                        'childAddress',
                        'postalCode',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['addressInformation', 'childAddress', 'postalCode'],
                        'onChangeText',
                        'value',
                        registrationInformation?.addressInformation?.isChildAddressUnknown === true
                            ? null
                            : formatPostalCode
                    )}
                />

                <SelectInput
                    {...selectFieldPropFactory(t('RegistrationDetails.homeAddress.homeAddress.city'), [
                        'addressInformation',
                        'childAddress',
                        'city',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['addressInformation', 'childAddress', 'city'],
                        'onChangeValue',
                        'value'
                    )}
                    options={cities}
                />
                <SelectInput
                    {...selectFieldPropFactory(t('RegistrationDetails.homeAddress.homeAddress.province'), [
                        'addressInformation',
                        'childAddress',
                        'province',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['addressInformation', 'childAddress', 'province'],
                        'onChangeValue',
                        'value'
                    )}
                    isLocked={true}
                    options={provinces}
                />

                <SelectInput
                    {...selectFieldPropFactory(t('RegistrationDetails.homeAddress.homeAddress.country'), [
                        'addressInformation',
                        'childAddress',
                        'country',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['addressInformation', 'childAddress', 'country'],
                        'onChangeValue',
                        'value'
                    )}
                    isLocked={true}
                    options={countries}
                />
            </Grid>
        </>
    );
};

export default HomeAddressDetails;
