import { FileContent } from '@Utilities';
import { baseApi, POST } from '../base';
import { BaseResponse } from '../commonTypes';
import {
    AnnotationIdParams,
    LinkRequestIdParam,
    LinkRequestInformation,
    LinkRequestNoteParam,
    LinkRequestStatusDetails,
    Note,
    NoteParams,
    LinkingRequest,
    LinkingRequestsParams,
} from './types';
import { DeepPartial } from '@reduxjs/toolkit';

const linkingRequestApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getLinkingRequests: builder.mutation<BaseResponse<LinkingRequest[]>, LinkingRequestsParams>({
            query: (body) => ({
                body,
                method: POST,
                url: 'StudentRegistration/GetLinkingRequests',
            }),
        }),
        exportLinkingRequests: builder.mutation<BaseResponse<FileContent>, LinkingRequestsParams>({
            query: (body) => ({
                method: POST,
                url: 'StudentRegistration/ExportLinkingRequests',
                body,
            }),
        }),
        getLinkRequest: builder.mutation<BaseResponse<LinkRequestInformation>, LinkRequestIdParam>({
            query: (body) => ({
                method: POST,
                url: 'StudentRegistration/GetLinkRequest',
                body,
            }),
        }),
        updateLinkRequest: builder.mutation<BaseResponse<string>, DeepPartial<LinkRequestInformation>>({
            query: (body) => ({
                method: POST,
                url: 'StudentRegistration/UpdateLinkRequest',
                body,
            }),
        }),
        updateLinkRequestStatus: builder.mutation<BaseResponse<string>, LinkRequestStatusDetails>({
            query: (body) => ({
                method: POST,
                url: 'StudentRegistration/UpdateLinkRequestStatus',
                body,
            }),
        }),
        createLinkRequestNote: builder.mutation<BaseResponse<Note[]>, LinkRequestNoteParam>({
            query: (body) => ({
                body,
                method: POST,
                url: 'StudentRegistration/CreateLinkRequestNote',
            }),
        }),
        getLinkRequestNotes: builder.mutation<BaseResponse<Note[]>, NoteParams>({
            query: (body) => ({
                body,
                method: POST,
                url: 'StudentRegistration/GetLinkRequestNotes',
            }),
        }),
        deleteLinkRequestNote: builder.mutation<BaseResponse<Note[]>, AnnotationIdParams>({
            query: (body) => ({
                method: POST,
                url: 'StudentRegistration/DeleteLinkRequestNote',
                body,
            }),
        }),
    }),
});

export const {
    useGetLinkingRequestsMutation,
    useExportLinkingRequestsMutation,
    useGetLinkRequestMutation,
    useUpdateLinkRequestMutation,
    useUpdateLinkRequestStatusMutation,
    useCreateLinkRequestNoteMutation,
    useGetLinkRequestNotesMutation,
    useDeleteLinkRequestNoteMutation,
} = linkingRequestApi;

export default linkingRequestApi;
