import { Box, Stack, useToast } from '@chakra-ui/react';
import { Pagination } from '@Components';
import DateCell from '@Components/DateCell';
import Table, { Column } from '@Components/Table';
import { useGetSchoolReportingPeriodJobsMutation } from '@Redux/services/Reports';
import { SortByProps, UploadData, UploadedJob } from '@Redux/services/Reports/types';
import { parseError, parseWarning } from '@Utilities';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_TIME_FORMAT, DEFAULT_PAGE_SIZE } from 'src/constants';
import JobProcessingStatusCell from './components/JobProcessingStatusCell';
import JobProcessingStatusDescriptionCell from './components/JobProcessingStatusDescriptionCell';

interface IProps {
    id: string;
    uploads: UploadData;
    reportingPeriodId: string;
    schoolYearId: string;
    schoolId: string;
    latestJob: UploadedJob;
}

export const UploadJobs = ({ id, uploads, reportingPeriodId, schoolYearId, schoolId, latestJob }: IProps) => {
    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const toast = useToast();
    const tableRef = useRef<HTMLDivElement>(null);
    const [columnWidth, setColumnWidth] = useState(0);

    const [uploadData, setUploadData] = useState(uploads);

    const defaultSortOrder = { id: 'createdOn', desc: true };
    const [sortBy, setSortBy] = useState<SortByProps>(defaultSortOrder);

    const [getUploadJobs, { isLoading }] = useGetSchoolReportingPeriodJobsMutation();

    const columns = useMemo<Column[]>(() => {
        const columns: Column[] = [
            {
                Header: <>&nbsp;</>,
                accessor: 'actions',
                headerAlign: 'center',
                disableSortBy: true,
                Cell: (row) => {
                    return JobProcessingStatusCell({ value: row.original.jobProcessingStatus });
                },
            },
            {
                Header: t('Records.files.date'),
                accessor: 'createdOn',
                Cell: DateCell(DATE_TIME_FORMAT),
                headerAlign: 'left',
                width: columnWidth * 0.8,
            },
            {
                Header: t('Records.files.status'),
                accessor: 'jobProcessingStatusDescription',
                Cell: (row) => JobProcessingStatusDescriptionCell(row.original),
                headerAlign: 'left',
                width: columnWidth,
            },
            {
                Header: t('Records.files.file'),
                accessor: 'fileNameClean',
                headerAlign: 'left',
                width: columnWidth,
            },
            {
                Header: t('Records.files.reportingType'),
                accessor: 'reportingType',
                headerAlign: 'left',
                width: columnWidth,
            },
            {
                Header: t('Records.files.author'),
                accessor: 'createdByFullName',
                headerAlign: 'left',
            },
        ];
        return columns;
    }, [columnWidth]);

    useEffect(() => {
        setColumnWidth(tableRef.current?.clientWidth / 6);
    }, []);

    const loadUploadJobs = async (currentPage: number, pageSize: number, sortBy: SortByProps) => {
        await getUploadJobs({
            currentPage: currentPage,
            pageSize: pageSize,
            sortBy: sortBy?.id,
            isDesc: sortBy?.desc,
            reportingPeriodId: reportingPeriodId,
            schoolYearId: schoolYearId,
            schoolId: schoolId,
        })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                setUploadData({ uploadedJobs: response.data, pagedResult: response.pagedResult });
            })
            .catch((err) => parseError(toast, err));
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        loadUploadJobs(page, pageSize, sortBy);
    };
    const handlePageSizeChange = (pageSize: number) => {
        setPageSize(pageSize);
        setCurrentPage(1);
        loadUploadJobs(currentPage, pageSize, sortBy);
    };
    const onSortChange = (sortBy) => {
        if (sortBy[0]) {
            setSortBy(sortBy[0]);
            loadUploadJobs(currentPage, pageSize, sortBy[0]);
        }
    };

    useEffect(() => {
        loadUploadJobs(currentPage, pageSize, sortBy);
    }, [latestJob]);

    return (
        <Stack id={id}>
            <Box
                ref={tableRef}
                bg='surface-primary'
                borderWidth={2}
                borderRadius={8}
                borderStyle='solid'
                borderColor='border-primary'
                overflowX={'auto'}
            >
                <Table
                    manualSortBy={true}
                    isDragDisabled={true}
                    containerStyle={{ borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderRadius: 0 }}
                    emptyMessage={t('Records.files.emptyTable')}
                    rawData={uploadData.uploadedJobs}
                    columns={columns}
                    isLoading={isLoading && uploadData.uploadedJobs.length === 0}
                    onSortChange={onSortChange}
                    manualPagination={true}
                />
                <Pagination
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                    pageSize={pageSize}
                    onPageSizeChange={handlePageSizeChange}
                    totalPages={uploads.pagedResult.pageCount}
                    totalResults={uploads.pagedResult.rowCount}
                    viewMode={'Numbered'}
                />
            </Box>
        </Stack>
    );
};
