import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormComponentProps, ManageDatePickerProps, ManageTextProps } from '@Hooks';
import { Box, Grid } from '@chakra-ui/react';
import SelectInput from '@Components/SelectInput';
import TextInput from '@Components/TextInput';
import DateTimeInput from '@Components/DateTimeInput';
import { useGetGradesRequestQuery } from '@Redux/services/LookupApi';
import { AcademicDetailsProps } from '@Pages/RegistrationDetails/types';
import { removeSpecialCharacters } from '@Pages/RegistrationDetails/helpers';

const AcademicDetails: React.FC<AcademicDetailsProps> = ({
    manageFieldPropFactory,
    selectFieldPropFactory,
    textFieldPropFactory,
    onGradeChange,
}) => {
    const [t] = useTranslation();
    const { data: { data: grades = [] } = {} } = useGetGradesRequestQuery({ searchText: '' });

    const handleGradeChange = async (event) => {
        const grade = event.target.value;
        onGradeChange(grade);
    };

    return (
        <>
            <Grid
                column={{ md: 2, sm: 1 }}
                columnGap={10}
                gridAutoFlow='dense'
                templateColumns={{
                    base: 'repeat(1, 1fr)',
                    md: 'repeat(2, 1fr)',
                }}
                rowGap={4}
                templateRows='auto'
                mb={6}
            >
                <SelectInput
                    {...selectFieldPropFactory(t('RegistrationDetails.aboutStudent.academicDetails.grade'), [
                        'childInformation',
                        'grade',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['childInformation', 'grade'],
                        'onChangeValue',
                        'value'
                    )}
                    options={grades}
                    onChange={handleGradeChange}
                />

                <DateTimeInput
                    {...textFieldPropFactory(t('RegistrationDetails.aboutStudent.academicDetails.startDate'), [
                        'childInformation',
                        'schoolStartDate',
                    ])}
                    {...manageFieldPropFactory<ManageDatePickerProps>(
                        ['childInformation', 'schoolStartDate'],
                        'onDateChange',
                        'date'
                    )}
                    size='md'
                />
            </Grid>

            <Box pb={4}>
                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.aboutStudent.academicDetails.otherInfo'), [
                        'childInformation',
                        'generalComments',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['childInformation', 'generalComments'],
                        'onChangeText',
                        'value',
                        removeSpecialCharacters
                    )}
                    col={1}
                />
            </Box>
        </>
    );
};

export default AcademicDetails;
