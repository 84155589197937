import { AlertError, AlertInfo, AlertSuccess, AlertWarning, Close } from '@Icon';
import {
    AlertDescription,
    AlertProps,
    AlertTitle,
    Box,
    Alert as ChakraAlert,
    Collapse,
    Flex,
    IconButton,
} from '@chakra-ui/react';
import { FC, useMemo } from 'react';

interface IProps {
    alertProps: AlertProps;
    title?: React.ReactNode | string;
    description?: React.ReactNode;
    isOpen: boolean;
    onClose: () => void;
}

export const Alert: FC<IProps> = ({ title, description, isOpen, onClose, alertProps }) => {
    const statusIconMap = useMemo(
        () => ({
            info: AlertInfo,
            warning: AlertWarning,
            success: AlertSuccess,
            error: AlertError,
        }),
        []
    );

    const AlertIcon = statusIconMap[alertProps.status];

    return (
        <Collapse in={isOpen} dir='bottom' style={{ overflow: 'visible', height: 'auto' }}>
            <Flex p='8px'>
                <ChakraAlert {...alertProps}>
                    <Box as='span' display='inherit' marginInlineEnd='3'>
                        <AlertIcon width='20px' height='20px' />
                    </Box>

                    <Box w='100%'>
                        {title ? (
                            <Flex justifyContent='space-between' w='100%'>
                                {title && <AlertTitle>{title} </AlertTitle>}
                                <IconButton
                                    variant={'ghost'}
                                    aria-label='close'
                                    icon={<Close width='20px' height='20px' />}
                                    onClick={onClose}
                                />
                            </Flex>
                        ) : (
                            <Flex justifyContent='space-between' w='100%'>
                                <AlertDescription whiteSpace='pre-wrap'>{description}</AlertDescription>
                                <IconButton
                                    variant={'ghost'}
                                    aria-label='close'
                                    icon={<Close width='20px' height='20px' />}
                                    onClick={onClose}
                                />
                            </Flex>
                        )}
                    </Box>
                </ChakraAlert>
            </Flex>
        </Collapse>
    );
};
