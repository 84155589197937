import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { LinkRequestInformation } from '@Redux/services/LinkingRequest/types';
import { useForms } from '@Hooks';
import { PartialDeep } from 'type-fest';
import {
    useGetLinkRequestMutation,
    useUpdateLinkRequestMutation,
    useUpdateLinkRequestStatusMutation,
    useCreateLinkRequestNoteMutation,
    useGetLinkRequestNotesMutation,
    useDeleteLinkRequestNoteMutation,
} from '@Redux/services/LinkingRequest';
import { useParams } from 'react-router-dom';
import { Box, Button, Flex, Stack, useDisclosure } from '@chakra-ui/react';
import { useToast } from '@Hooks/useToast';
import Sidebar from '@Components/Sidebar';
import { Trans, useTranslation } from 'react-i18next';
import { sideBarData } from './components/Sidebar';
import { LinkRequestSidebarItem, UpdateStatuses } from './types';
import Cycle from '@Icon/Cycle';
import { LinkingRequestStatus } from 'src/constants';
import Steps from '@Components/Steps';
import { ArrowForwardIcon } from '@Icon';
import FormHeader from '@Components/FormHeader';
import InnerFormHeader from './components/InnerFormHeader';
import SvgAboutStudent from '@Icon/AboutStudent';
import {
    getErrorsAssociatedWithFields,
    getMutableForAccessor,
    parseError,
    parseWarning,
    ValidatorType,
} from '@Utilities';
import { DetailPageSkeleton } from '@Components/DetailPageSkeleton';
import { Validator } from './validator';
import { Note, PagedResult } from '@Redux/services/commonTypes';
import AboutStudent from './components/AboutStudent';
import StatusChangesDialog from './components/StatusChangeDialog';
import Notes from '@Components/Notes';
import { useMsal } from '@azure/msal-react';
import { useGetSchoolsByGradeMutation } from '@Redux/services/LookupApi';
import { Prompt } from '@Components/Prompt';
import GuardianContactDetails from './components/GuardianContactDetails';

const UpdateDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [t] = useTranslation();
    const sideBarItems = useMemo(() => sideBarData(LinkRequestSidebarItem.AboutStudent, t), []);
    const [nextButtonLoading, setNextButtonLoading] = useState(false);
    const [rejected, setRejected] = useState(false);
    const [saveValidationError, setSaveValidationError] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoading, setIsLoading] = React.useState(false);
    const [schools, setSchools] = useState([]);
    const toast = useToast();

    const { accounts } = useMsal();
    const account = accounts[0];

    const linkRequestRequiredFields = (rules: ValidatorType[]) => {
        if (!linkRequestInformation) return;
        const newLinkRequestRequiredFields = {};
        rules.map((validaton) => {
            newLinkRequestRequiredFields[validaton.accessorPath.join('.')] = validaton;
        });
        return newLinkRequestRequiredFields;
    };

    const isPageReadOnly = () =>
        linkRequestInformation?.ucdsbStatus === LinkingRequestStatus.Deleted ||
        linkRequestInformation?.ucdsbStatus === LinkingRequestStatus.Approved ||
        linkRequestInformation?.ucdsbStatus === LinkingRequestStatus.Rejected;

    const getCommonProps = () => {
        return {
            isLocked: isPageReadOnly(),
        };
    };

    const {
        formData: linkRequestInformation,
        errors,
        textFieldPropFactory,
        selectFieldPropFactory,
        setErrors,
        manageFieldPropFactory,
        setFormData,
        setIsDirty,
        isDirty,
        setDirtyData,
        dirtyAccessors,
        setDirtyAccessor,
    } = useForms<PartialDeep<LinkRequestInformation>>({}, getCommonProps);
    const containerRef = useRef<HTMLDivElement>(null);
    const componentProps = {
        manageFieldPropFactory,
        selectFieldPropFactory,
        textFieldPropFactory,
    };

    const [updateLinkRequestStatus] = useUpdateLinkRequestStatusMutation();
    const [getLinkRequest] = useGetLinkRequestMutation();
    const [updateLinkRequest] = useUpdateLinkRequestMutation();
    const [createLinkRequestNote] = useCreateLinkRequestNoteMutation();
    const [getLinkRequestNotes, { isLoading: isNotesLoading }] = useGetLinkRequestNotesMutation();
    const [deleteLinkRequestNote] = useDeleteLinkRequestNoteMutation();
    const [getSchoolsByGrade] = useGetSchoolsByGradeMutation();

    const steps = useMemo(() => {
        return [
            {
                active: LinkingRequestStatus.Submitted === linkRequestInformation?.ucdsbStatus,
                header: t('LinkingRequestDetails.step1'),
                subHeader: t('LinkingRequestDetails.step1Subheader'),
            },
            {
                active: LinkingRequestStatus.Approved === linkRequestInformation?.ucdsbStatus,
                header: t('LinkingRequestDetails.step2'),
                subHeader: t('LinkingRequestDetails.step2Subheader'),
            },
        ];
    }, [linkRequestInformation]);

    const getNextStatus = (status: number) => {
        switch (status) {
            case LinkingRequestStatus.Submitted:
                return LinkingRequestStatus.Approved;
            default:
                return LinkingRequestStatus.Approved;
        }
    };

    const validate = (validationRules: ValidatorType[]) => {
        const newErrors = { ...errors };
        const nextErrors: Record<string, string> = {};

        for (const rule of validationRules) {
            const { accessorPath, validator, message, required } = rule;

            const finalMutable = getMutableForAccessor(linkRequestInformation, accessorPath);
            const finalProperty = accessorPath[accessorPath.length - 1];

            if (
                required &&
                (rule.editableStatus == null ||
                    rule.editableStatus?.indexOf(linkRequestInformation.ucdsbStatus) > -1) &&
                !validator(finalMutable[finalProperty])
            ) {
                nextErrors[accessorPath.join('.')] = message;
            } else {
                newErrors[accessorPath.join('.')] && delete newErrors[accessorPath.join('.')];
            }
        }
        setErrors({ ...newErrors, ...nextErrors });
        if (Object.keys(nextErrors).length) {
            toast({
                status: 'error',
                position: 'top',
                description: t('RegistrationDetails.toastErrorDescription'),
            });
            return false;
        }
        setSaveValidationError(false);
        return true;
    };

    useEffect(() => {
        setIsLoading(true);
        getLinkRequest({ linkRequestId: id })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                let data = response.data as LinkRequestInformation;
                const notesObject = { ...data.notes };
                const notes = notesObject.studentLinkingNotesInfo.map((note) => {
                    return {
                        ...note,
                        canDelete: account.username === note.modifiedByEmailAddress,
                        canEdit: false,
                    };
                });
                notesObject.studentLinkingNotesInfo = notes;
                data = {
                    ...data,
                    notes: notesObject,
                };
                handleGradeChange(data.grade ? data.grade.toString() : '');
                setFormData(data);
            })
            .catch((error) => {
                parseError(toast, error);
            })
            .finally(() => {
                setIsLoading(false);
                setIsDirty(false);
            });
    }, []);

    const handleNext = useCallback(
        (statusComment: string) => {
            handleSave().then(() => {
                setNextButtonLoading(true);

                const status = getNextStatus(linkRequestInformation.ucdsbStatus);
                updateLinkRequestStatus({
                    linkingId: linkRequestInformation.id,
                    status: status,
                    statusComments: statusComment,
                })
                    .unwrap()
                    .then(() => {
                        getLinkRequestNotes({ objectId: id, currentPage: 1, pageSize: 10 })
                            .unwrap()
                            .then((response) => {
                                const notesObject = { ...response };
                                const notes = notesObject.data.map((note) => {
                                    return {
                                        ...note,
                                        canDelete: account.username === note.modifiedByEmailAddress,
                                        canEdit: false,
                                    };
                                });
                                notesObject.data = notes;

                                setFormData({
                                    ...linkRequestInformation,
                                    ucdsbStatus: status,
                                    notes: {
                                        studentLinkingNotesInfo: notesObject.data,
                                        pagedResult: notesObject.pagedResult,
                                    },
                                });
                            })
                            .catch((error) => {
                                parseError(toast, error);
                            });

                        toast({
                            status: 'success',
                            description: t('LinkingRequestDetails.toastApprovedSuccess'),
                        });
                    })
                    .catch((error) => {
                        setErrors(
                            getErrorsAssociatedWithFields(error.data.errors, Object.keys(linkRequestRequiredFields))
                        );
                        parseError(toast, error);
                    })
                    .finally(() => {
                        setNextButtonLoading(false);
                    });
            });
        },
        [linkRequestInformation, setErrors, nextButtonLoading]
    );

    const handleSaveDlgOpen = async () => {
        setRejected(false);
        const rules = Validator(linkRequestInformation, t);
        if (!validate(rules) || saveValidationError || !linkRequestInformation || isPageReadOnly()) return;
        onOpen();
    };

    const handleSave = async () => {
        try {
            const rules = Validator(linkRequestInformation, t, true);
            if (!validate(rules) || saveValidationError || !linkRequestInformation || isPageReadOnly()) return;

            const response = await updateLinkRequest(linkRequestInformation).unwrap();

            parseWarning(toast, response);
            setDirtyData({});
            setIsDirty(false);
            setDirtyAccessor([]);

            const message = t('LinkingRequestDetails.savedSuccessfully');

            toast({
                status: 'success',
                description: message,
            });
        } catch (error) {
            const nextErrors: Record<string, string> = {};
            for (const dirtyAccessor of dirtyAccessors) {
                nextErrors[dirtyAccessor.join('.')] = t('RegistrationDetails.fieldNotSaved');
            }
            setErrors(getErrorsAssociatedWithFields(error.data.errors, Object.keys(linkRequestRequiredFields)));
            parseError(toast, error);
        }
    };

    const handleRejectDlgOpen = async () => {
        setRejected(true);
        onOpen();
    };

    const handleReject = useCallback(
        async (statusComment: string) => {
            const status = LinkingRequestStatus.Rejected;

            await updateLinkRequestStatus({
                linkingId: linkRequestInformation.id,
                status: status,
                statusComments: statusComment,
            })
                .unwrap()
                .then(async () => {
                    getLinkRequestNotes({ objectId: id, currentPage: 1, pageSize: 10 })
                        .unwrap()
                        .then((response) => {
                            const notesObject = { ...response };
                            const notes = notesObject.data.map((note) => {
                                return {
                                    ...note,
                                    canDelete: account.username === note.modifiedByEmailAddress,
                                    canEdit: false,
                                };
                            });
                            notesObject.data = notes;

                            setFormData({
                                ...linkRequestInformation,
                                ucdsbStatus: status,
                                notes: {
                                    studentLinkingNotesInfo: notesObject.data,
                                    pagedResult: notesObject.pagedResult,
                                },
                            });
                        })
                        .catch((error) => {
                            parseError(toast, error);
                        });

                    toast({
                        status: 'success',
                        description: t('LinkingRequestDetails.toastRejected'),
                    });
                })
                .catch((error) => {
                    setErrors(getErrorsAssociatedWithFields(error.data.errors, Object.keys(linkRequestRequiredFields)));
                    parseError(toast, error);
                });
        },
        [linkRequestInformation, setErrors]
    );

    const handleNotePageChange = async (pageNumber: number, pageSize: number) => {
        await getLinkRequestNotes({
            objectId: linkRequestInformation?.id,
            pageSize,
            currentPage: pageNumber,
        })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                const notesObject = { ...response };
                const notes = notesObject.data.map((note) => {
                    return {
                        ...note,
                        canDelete: account.username === note.modifiedByEmailAddress,
                        canEdit: false,
                    };
                });
                notesObject.data = notes;

                setFormData({
                    ...linkRequestInformation,
                    notes: { studentLinkingNotesInfo: notesObject.data, pagedResult: notesObject.pagedResult },
                });
            })
            .catch((e) => parseError(toast, e));
    };

    const handleAddNote = async (noteText: string) => {
        await createLinkRequestNote({
            objectId: linkRequestInformation?.id,
            noteText,
        })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                const notesObject = { ...response };
                const notes = notesObject.data.map((note) => {
                    return {
                        ...note,
                        canDelete: account.username === note.modifiedByEmailAddress,
                        canEdit: false,
                    };
                });
                notesObject.data = notes;

                setFormData({
                    ...linkRequestInformation,
                    notes: { studentLinkingNotesInfo: notesObject.data, pagedResult: notesObject.pagedResult },
                });
            })
            .catch((e) => parseError(toast, e));
    };

    const handleDeleteNote = async (annotationId: string) => {
        await deleteLinkRequestNote({ annotationId })
            .unwrap()
            .then((response) => {
                const notesObject = { ...response };
                const notes = notesObject.data.map((note) => {
                    return {
                        ...note,
                        canDelete: account.username === note.modifiedByEmailAddress,
                        canEdit: false,
                    };
                });
                notesObject.data = notes;

                setFormData({
                    ...linkRequestInformation,
                    notes: { studentLinkingNotesInfo: notesObject.data, pagedResult: notesObject.pagedResult },
                });
                if (response?.warningResult?.showAlert) {
                    parseWarning(toast, response);
                } else {
                    toast({
                        status: 'success',
                        description: t('LinkingRequestDetails.toastSuccessNoteDelete'),
                    });
                }
            })
            .catch((e) => parseError(toast, e));
    };

    const handleGradeChange = async (grade?: string) => {
        getSchoolsByGrade({
            grade: grade,
            searchText: '',
        })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                setSchools(response.data);
            })
            .catch((e) => parseError(toast, e));
        setFormData((linkingRequest) => ({
            ...linkingRequest,
            grade: grade ? Number(grade) : null,
        }));
        setIsDirty(true);
    };

    if (isLoading) {
        return <DetailPageSkeleton />;
    }

    return (
        <>
            <Flex>
                <Sidebar
                    {...sideBarItems}
                    footerDetails={{
                        createdOn: linkRequestInformation?.createdOn,
                        createdByFullName: linkRequestInformation?.createdByFullName,
                        modifiedByFullName: linkRequestInformation?.modifiedByFullName,
                        modifiedOn: linkRequestInformation?.modifiedOn,
                        onSave: handleSave,
                        disabled: !isDirty,
                    }}
                />
                <Box ref={containerRef} overflow={'hidden'} width='100%'>
                    <form>
                        <Flex justifyContent='space-between' direction={{ base: 'column', md: 'row' }} pt={8} pr={4}>
                            <Steps steps={steps} />
                            <Box>
                                <Button
                                    isDisabled={isPageReadOnly()}
                                    onClick={handleRejectDlgOpen}
                                    textStyle={'md-semibold'}
                                    variant={
                                        linkRequestInformation.ucdsbStatus === LinkingRequestStatus.Rejected
                                            ? 'primary'
                                            : null
                                    }
                                    mr={3}
                                    disabled={isPageReadOnly()}
                                    title={t('LinkingRequestDetails.reject')}
                                >
                                    {t('LinkingRequestDetails.reject')}
                                </Button>
                                <Button
                                    isDisabled={isPageReadOnly()}
                                    onClick={handleSaveDlgOpen}
                                    textStyle={'md-semibold'}
                                    variant={
                                        linkRequestInformation.ucdsbStatus === LinkingRequestStatus.Rejected
                                            ? null
                                            : nextButtonLoading
                                            ? 'brand-inverted'
                                            : 'primary'
                                    }
                                    rightIcon={
                                        !isPageReadOnly() ? (
                                            nextButtonLoading ? (
                                                <Cycle className='spin-loading' width='20px' height='20px' />
                                            ) : (
                                                <ArrowForwardIcon width='20px' height='20px' />
                                            )
                                        ) : null
                                    }
                                    mr={2}
                                >
                                    {!isPageReadOnly() ? (
                                        <Trans
                                            i18nKey={
                                                nextButtonLoading
                                                    ? 'LinkingRequestDetails.nextStepLoading'
                                                    : 'LinkingRequestDetails.nextStep'
                                            }
                                            values={{
                                                seperator: ':',
                                                sepLabel: t('LinkingRequestDetails.nextStep'),
                                                stepLabel:
                                                    UpdateStatuses[getNextStatus(linkRequestInformation.ucdsbStatus)],
                                            }}
                                        />
                                    ) : (
                                        UpdateStatuses[getNextStatus(linkRequestInformation.ucdsbStatus)]
                                    )}
                                </Button>
                            </Box>
                        </Flex>
                        <Stack bg='surface-secondary' p={3} mt={5} spacing={8} borderLeftRadius={15}>
                            <FormHeader
                                header={
                                    <InnerFormHeader
                                        divider={true}
                                        title={t('LinkingRequestDetails.aboutStudent')}
                                        icon={<SvgAboutStudent />}
                                    />
                                }
                                label={t('LinkingRequestDetails.studentDetail')}
                                id='aboutstudent'
                            >
                                <AboutStudent
                                    schools={schools}
                                    handleGradeChange={handleGradeChange}
                                    {...componentProps}
                                />
                            </FormHeader>
                            <GuardianContactDetails id='guardiancontactdetails' {...componentProps} />
                            <Notes
                                id='notes'
                                {...componentProps}
                                notes={linkRequestInformation?.notes?.studentLinkingNotesInfo as Note[]}
                                onAddNote={async (noteText: string) => {
                                    handleAddNote(noteText);
                                }}
                                onDeleteNote={(annotationId: string) => handleDeleteNote(annotationId)}
                                notePagination={linkRequestInformation?.notes?.pagedResult as PagedResult}
                                isNotesLoading={isNotesLoading}
                                onNextPage={handleNotePageChange}
                                isNoteEditable={!isPageReadOnly()}
                                hideActivities={true}
                                defaultPageSize={linkRequestInformation?.notes?.pagedResult.pageSize}
                            />
                        </Stack>
                    </form>
                </Box>
            </Flex>
            {isOpen && (
                <StatusChangesDialog
                    isOpen={isOpen}
                    onSave={rejected === false ? handleNext : handleReject}
                    onClose={onClose}
                    status={
                        rejected === false
                            ? UpdateStatuses[getNextStatus(linkRequestInformation?.ucdsbStatus)]
                            : UpdateStatuses[LinkingRequestStatus.Rejected]
                    }
                />
            )}
            {isDirty && (
                <Prompt
                    title={t('UpdateDetails.Prompt.title')}
                    message={t('UpdateDetails.Prompt.message')}
                    submitLabel={t('UpdateDetails.Prompt.leave')}
                    cancelLabel={t('UpdateDetails.Prompt.cancelButtonLabel')}
                    dirty={isDirty}
                    onLeave={() => setIsDirty(false)}
                />
            )}
        </>
    );
};

export default UpdateDetails;
