import { TFunction } from 'react-i18next';
import { LinkingRequestStatusParam } from '../index';

const sideBarData = (updateStatus: LinkingRequestStatusParam, t: TFunction) => ({
    groups: [
        {
            items: [
                {
                    label: t('LinkingRequest.Status.all'),
                    id: '/linking-requests/all',
                    active: updateStatus === 'all',
                },
                {
                    label: t('LinkingRequest.Status.submitted'),
                    id: '/linking-requests/submitted',
                    active: updateStatus === 'submitted',
                },
                {
                    label: t('LinkingRequest.Status.approved'),
                    id: '/linking-requests/approved',
                    active: updateStatus === 'approved',
                },
                {
                    label: t('LinkingRequest.Status.deleted'),
                    id: '/linking-requests/deleted',
                    active: updateStatus === 'deleted',
                },
                {
                    label: t('LinkingRequest.Status.rejected'),
                    id: '/linking-requests/rejected',
                    active: updateStatus === 'rejected',
                },
            ],
        },
    ],
});

export { sideBarData };
