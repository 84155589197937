import { Address, EmergencyContactInformation } from '@Redux/services/Registration/types';
import { RegistrationStatus } from 'src/constants';

export const getNextStatus = (status: number) => {
    switch (status) {
        case RegistrationStatus.Submitted:
            return RegistrationStatus.DocumentsRequired;
        case RegistrationStatus.DocumentsRequired:
            return RegistrationStatus.Approved;
        default:
            return RegistrationStatus.Approved;
    }
};

export function isAnyAddressPropertySet(addressInfo: Address): boolean {
    return Object.keys(addressInfo).some((key) => addressInfo[key as keyof Address] !== null);
}

export function populateEmergencyContacts(
    emergencyContactInfo: EmergencyContactInformation
): EmergencyContactInformation {
    const { emergencyContacts } = emergencyContactInfo;
    if (emergencyContacts && emergencyContacts.length > 0) {
        const [contact1, contact2, contact3, contact4] = emergencyContacts;
        return {
            ...emergencyContactInfo,
            emergencyContact1: contact1,
            emergencyContact2: contact2,
            emergencyContact3: contact3,
            emergencyContact4: contact4,
        };
    }
    return {
        ...emergencyContactInfo,
        emergencyContact1: null,
        emergencyContact2: null,
        emergencyContact3: null,
        emergencyContact4: null,
    };
}

export function populateEmergencyContactsArray(
    emergencyContactInfo: EmergencyContactInformation
): EmergencyContactInformation {
    const { emergencyContact1, emergencyContact2, emergencyContact3, emergencyContact4 } = emergencyContactInfo;
    return {
        ...emergencyContactInfo,
        emergencyContacts: [emergencyContact1, emergencyContact2, emergencyContact3, emergencyContact4].filter(
            (contact) => contact
        ),
    };
}

export const formatPhoneNumber = (value: string) =>
    value
        .replace(/\D/g, '') // Remove all non-digit characters
        .replace(/^0+/, '') // Remove leading zeros
        .slice(0, 10) // Take the first 10 digits
        .replace(
            /(\d{3})(\d{0,3})(\d{0,4})/,
            (_, p1, p2, p3) => [p1, p2, p3].filter(Boolean).join('-') // Format as xxx-xxx-xxxx
        );

export function formatPostalCode(val: string) {
    const sanitizedVal = val
        .toUpperCase()
        .replace(/[^A-Z0-9]+/g, '')
        .substring(0, Math.min(val?.length, 6));

    const invalidIdx = [...sanitizedVal].findIndex(
        (character, idx) => !(idx % 2 === 0 ? Boolean(character.match(/[A-Z]/i)) : Boolean(character.match(/[0-9]/i)))
    );

    const partialPostal = invalidIdx === -1 ? sanitizedVal : sanitizedVal.substring(0, invalidIdx);
    return (partialPostal.substring(0, 3) + ' ' + partialPostal.substring(3)).trim();
}

export const removeSpecialCharacters = (value: string) => {
    return value.replace(/[&<"]/g, '');
};
