import { ValidatorType } from '@Utilities';
import { TFunction } from 'react-i18next';
import type { PartialDeep } from 'type-fest';
import { RegistrationInformation } from '@Redux/services/Registration/types';
import { RegistrationStatus } from 'src/constants';

export function Validator(
    registrationInformation: PartialDeep<RegistrationInformation>,
    t: TFunction
): ValidatorType[] {
    // emergency contact required if any fields are filled in
    const contact1Vals = Object.values(registrationInformation?.emergencyContactInformation?.emergencyContact1 || {});
    const hasContact1Value = contact1Vals.some(
        (i) => !Number.isNaN(i) && typeof i !== 'boolean' && i?.toString()?.trim()?.length > 0
    );
    const contact2Vals = Object.values(registrationInformation?.emergencyContactInformation?.emergencyContact2 || {});
    const hasContact2Value = contact2Vals.some(
        (i) => !Number.isNaN(i) && typeof i !== 'boolean' && i?.toString()?.trim()?.length > 0
    );
    const contact3Vals = Object.values(registrationInformation?.emergencyContactInformation?.emergencyContact3 || {});
    const hasContact3Value = contact3Vals.some(
        (i) => !Number.isNaN(i) && typeof i !== 'boolean' && i?.toString()?.trim()?.length > 0
    );
    const contact4Vals = Object.values(registrationInformation?.emergencyContactInformation?.emergencyContact4 || {});
    const hasContact4Value = contact4Vals.some(
        (i) => !Number.isNaN(i) && typeof i !== 'boolean' && i?.toString()?.trim()?.length > 0
    );

    return [
        {
            accessorPath: ['childInformation', 'legalFirstName'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },
        {
            accessorPath: ['childInformation', 'legalSurname'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },
        {
            accessorPath: ['childInformation', 'preferredFirstName'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [RegistrationStatus.DocumentsRequired, RegistrationStatus.Approved],
        },
        {
            accessorPath: ['childInformation', 'preferredSurname'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [RegistrationStatus.DocumentsRequired, RegistrationStatus.Approved],
        },
        {
            accessorPath: ['childInformation', 'preferredGender'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: false,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },
        {
            accessorPath: ['childInformation', 'dateOfBirth'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },
        {
            accessorPath: ['childInformation', 'phoneNumber'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },
        {
            accessorPath: ['childInformation', 'grade'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },
        {
            accessorPath: ['childInformation', 'schoolStartDate'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [RegistrationStatus.Approved],
        },
        {
            accessorPath: ['addressInformation', 'childAddress', 'postalCode'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },
        {
            accessorPath: ['addressInformation', 'childAddress', 'city'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },
        {
            accessorPath: ['addressInformation', 'parentAddress', 'postalCode'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },
        {
            accessorPath: ['addressInformation', 'parentAddress', 'cityFreeForm'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },
        {
            accessorPath: ['citizenshipInformation', 'firstLanguage'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },
        {
            accessorPath: ['citizenshipInformation', 'languageAtHome'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },

        {
            accessorPath: ['citizenshipInformation', 'boardResidenceStatus'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [RegistrationStatus.Approved],
        },
        {
            accessorPath: ['citizenshipInformation', 'residenceStatus'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [RegistrationStatus.Approved],
        },
        {
            accessorPath: ['citizenshipInformation', 'countryOfCitizenship'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [RegistrationStatus.Approved],
        },

        {
            accessorPath: ['schoolInformation', 'id'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },

        {
            accessorPath: ['emergencyContactInformation', 'parentEmailAddress'],
            validator: (value: string) => !value || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
            message: t('RegistrationDetails.invalidEmail'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },

        {
            accessorPath: ['emergencyContactInformation', 'parent', 'relationshipToStudent'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },
        {
            accessorPath: ['emergencyContactInformation', 'parent', 'surname'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },
        {
            accessorPath: ['emergencyContactInformation', 'parent', 'language'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },
        {
            accessorPath: ['emergencyContactInformation', 'parent', 'primaryPhoneNumber'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: false,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },
        {
            accessorPath: ['emergencyContactInformation', 'parent', 'firstName'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [
                RegistrationStatus.Submitted,
                RegistrationStatus.DocumentsRequired,
                RegistrationStatus.Approved,
            ],
        },

        {
            accessorPath: ['emergencyContactInformation', 'emergencyContact1', 'relationshipToStudent'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: hasContact1Value,
        },
        {
            accessorPath: ['emergencyContactInformation', 'emergencyContact1', 'firstName'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: hasContact1Value,
        },
        {
            accessorPath: ['emergencyContactInformation', 'emergencyContact1', 'surname'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: hasContact1Value,
        },
        {
            accessorPath: ['emergencyContactInformation', 'emergencyContact1', 'primaryPhoneNumber'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: hasContact1Value,
        },
        {
            accessorPath: ['emergencyContactInformation', 'emergencyContact1', 'language'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: hasContact1Value,
        },

        {
            accessorPath: ['emergencyContactInformation', 'emergencyContact2', 'relationshipToStudent'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: hasContact2Value,
        },
        {
            accessorPath: ['emergencyContactInformation', 'emergencyContact2', 'firstName'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: hasContact2Value,
        },
        {
            accessorPath: ['emergencyContactInformation', 'emergencyContact2', 'surname'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: hasContact2Value,
        },
        {
            accessorPath: ['emergencyContactInformation', 'emergencyContact2', 'primaryPhoneNumber'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: hasContact2Value,
        },
        {
            accessorPath: ['emergencyContactInformation', 'emergencyContact2', 'language'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: hasContact2Value,
        },

        {
            accessorPath: ['emergencyContactInformation', 'emergencyContact3', 'relationshipToStudent'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: hasContact3Value,
        },
        {
            accessorPath: ['emergencyContactInformation', 'emergencyContact3', 'firstName'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: hasContact3Value,
        },
        {
            accessorPath: ['emergencyContactInformation', 'emergencyContact3', 'surname'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: hasContact3Value,
        },
        {
            accessorPath: ['emergencyContactInformation', 'emergencyContact3', 'primaryPhoneNumber'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: hasContact3Value,
        },
        {
            accessorPath: ['emergencyContactInformation', 'emergencyContact3', 'language'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: hasContact3Value,
        },

        {
            accessorPath: ['emergencyContactInformation', 'emergencyContact4', 'relationshipToStudent'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: hasContact4Value,
        },
        {
            accessorPath: ['emergencyContactInformation', 'emergencyContact4', 'firstName'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: hasContact4Value,
        },
        {
            accessorPath: ['emergencyContactInformation', 'emergencyContact4', 'surname'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: hasContact4Value,
        },
        {
            accessorPath: ['emergencyContactInformation', 'emergencyContact4', 'primaryPhoneNumber'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: hasContact4Value,
        },
        {
            accessorPath: ['emergencyContactInformation', 'emergencyContact4', 'language'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: hasContact4Value,
        },

        {
            accessorPath: ['sourceDocument'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [RegistrationStatus.Approved],
        },
        {
            accessorPath: ['admissionReason'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
            editableStatus: [RegistrationStatus.Approved],
        },
    ];
}
