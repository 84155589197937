import { LinkingRequestStatus } from 'src/constants';
import { FormComponentProps } from '@Hooks';
import { Note, PagedResult } from '@Redux/services/commonTypes';
import { BasicLookup } from '@Redux/services/LookupApi/types';

export const enum LinkRequestSidebarItem {
    AboutStudent = 0,
    GuardianContactDetails = 1,
    Notes = 2,
}

export const UpdateStatuses = {
    [LinkingRequestStatus.Submitted]: 'Submitted',
    [LinkingRequestStatus.Approved]: 'Approved',
    [LinkingRequestStatus.Rejected]: 'Rejected',
    [LinkingRequestStatus.Deleted]: 'Deleted',
};

export interface NotesProps extends FormComponentProps {
    notes: Note[];
    notePagination?: PagedResult;
    isNotesLoading?: boolean;
    hideActivities?: boolean;
    onAddNote?: (noteText: string) => Promise<void>;
    onDeleteNote?: (annotationId: string) => Promise<void>;
    onUpdateNote?: (noteText: string, annotationId: string) => Promise<void>;
    onNextPage?: (currentPage: number, pageSize: number) => Promise<void>;
    isNoteEditable?: boolean;
    hideFormHeader?: boolean;
}

export interface LinkingRequestProps extends FormComponentProps {
    schools: BasicLookup[];
    handleGradeChange?: (grade?: string) => Promise<void>;
}
