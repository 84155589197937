import FormHeader from '@Components/FormHeader';
import React from 'react';
import InnerFormHeader from '../InnerFormHeader';
import { FormComponentProps } from '@Hooks';
import { useTranslation } from 'react-i18next';
import Contact from './components/Contact';
import { useGetRelationshipsRequestQuery, useGetLanguagesRequestQuery } from '@Redux/services/LookupApi';
import SvgContact from '@Icon/Contact';

const Contacts: React.FC<FormComponentProps> = ({
    id,
    manageFieldPropFactory,
    selectFieldPropFactory,
    textFieldPropFactory,
    switchFieldPropFactory,
}) => {
    const [t] = useTranslation();
    const componentProps = {
        textFieldPropFactory,
        selectFieldPropFactory,
        manageFieldPropFactory,
        switchFieldPropFactory,
    };

    const { data: { data: relationships = [] } = {} } = useGetRelationshipsRequestQuery({ searchText: '' });
    const { data: { data: languages = [] } = {} } = useGetLanguagesRequestQuery({ searchText: '' });

    return (
        <>
            <FormHeader
                id={id}
                header={
                    <InnerFormHeader divider={true} title={t('RegistrationDetails.contacts')} icon={<SvgContact />} />
                }
                label={t('RegistrationDetails.contact.contact1')}
            >
                <Contact
                    contactIndex={1}
                    id={'contact1Section'}
                    relationships={relationships}
                    languages={languages}
                    {...componentProps}
                />
            </FormHeader>
            <FormHeader id={id} label={t('RegistrationDetails.contact.contact2')}>
                <Contact
                    contactIndex={2}
                    id={'contact2Section'}
                    relationships={relationships}
                    languages={languages}
                    {...componentProps}
                />
            </FormHeader>
            <FormHeader id={id} label={t('RegistrationDetails.contact.contact3')}>
                <Contact
                    contactIndex={3}
                    id={'contact3Section'}
                    relationships={relationships}
                    languages={languages}
                    {...componentProps}
                />
            </FormHeader>
            <FormHeader id={id} label={t('RegistrationDetails.contact.contact4')}>
                <Contact
                    contactIndex={4}
                    id={'contact4Section'}
                    relationships={relationships}
                    languages={languages}
                    {...componentProps}
                />
            </FormHeader>
        </>
    );
};

export default Contacts;
