import FormHeader from '@Components/FormHeader';
import React from 'react';
import InnerFormHeader from '../InnerFormHeader';
import { FormComponentProps, ManageTextProps, ManageSwitchInputProps } from '@Hooks';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@chakra-ui/react';
import Switch from '@Components/Switch';
import TextInput from '@Components/TextInput';
import SvgSchool from '@Icon/School';
import { useGetSchoolsQuery } from '@Redux/services/LookupApi';
import SelectInput from '@Components/SelectInput';
import { SchoolsProps } from '@Pages/RegistrationDetails/types';
import { removeSpecialCharacters } from '@Pages/RegistrationDetails/helpers';

const School: React.FC<SchoolsProps> = ({
    id,
    schools,
    manageFieldPropFactory,
    selectFieldPropFactory,
    switchFieldPropFactory,
    textFieldPropFactory,
}) => {
    const [t] = useTranslation();
    //const { data: { data: schools = [] } = {} } = useGetSchoolsQuery({ searchText: '' });

    return (
        <FormHeader
            id={id}
            header={<InnerFormHeader divider={true} title={t('RegistrationDetails.school')} icon={<SvgSchool />} />}
            label={''}
        >
            <Grid
                column={{ md: 32, sm: 1 }}
                columnGap={10}
                gridAutoFlow='dense'
                templateColumns={{
                    base: 'repeat(1, 1fr)',
                    md: 'repeat(2, 1fr)',
                }}
                rowGap={4}
                templateRows='auto'
                mb={6}
            >
                <Box gridColumn='span 2' pb={4}>
                    <SelectInput
                        {...selectFieldPropFactory(t('RegistrationDetails.school.selectedSchool'), [
                            'schoolInformation',
                            'id',
                        ])}
                        {...manageFieldPropFactory<ManageTextProps>(
                            ['schoolInformation', 'id'],
                            'onChangeValue',
                            'value'
                        )}
                        options={schools}
                        col={1}
                    />
                </Box>
                <Switch
                    {...switchFieldPropFactory(t('RegistrationDetails.school.frenchImmersionSchool'), [
                        'schoolInformation',
                        'immersionSchool',
                    ])}
                    {...manageFieldPropFactory<ManageSwitchInputProps>(
                        ['schoolInformation', 'immersionSchool'],
                        'onChangeSwitch',
                        'isChecked'
                    )}
                />
                <Switch
                    {...switchFieldPropFactory(t('RegistrationDetails.school.registeringAtHomeSchool'), [
                        'schoolInformation',
                        'registeringAtHomeSchool',
                    ])}
                    {...manageFieldPropFactory<ManageSwitchInputProps>(
                        ['schoolInformation', 'registeringAtHomeSchool'],
                        'onChangeSwitch',
                        'isChecked'
                    )}
                />
                <Box gridColumn='span 2'>
                    <Switch
                        {...switchFieldPropFactory(t('RegistrationDetails.school.crossBoundary'), [
                            'schoolInformation',
                            'crossBoundary',
                        ])}
                        {...manageFieldPropFactory<ManageSwitchInputProps>(
                            ['schoolInformation', 'crossBoundary'],
                            'onChangeSwitch',
                            'isChecked'
                        )}
                        col={1}
                    />
                </Box>
                <Box gridColumn='span 2'>
                    <TextInput
                        {...textFieldPropFactory(t('RegistrationDetails.school.crossBoundaryExplantion'), [
                            'schoolInformation',
                            'crossBoundaryExplanation',
                        ])}
                        {...manageFieldPropFactory<ManageTextProps>(
                            ['schoolInformation', 'crossBoundaryExplanation'],
                            'onChangeText',
                            'value',
                            removeSpecialCharacters
                        )}
                        col={1}
                    />
                </Box>
            </Grid>
        </FormHeader>
    );
};

export default School;
