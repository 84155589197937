import SelectInput from '@Components/SelectInput';
import { ManageTextProps } from '@Hooks';
import { Grid } from '@chakra-ui/react';
import { FC } from 'react';
import TextInput from '@Components/TextInput';
import { useTranslation } from 'react-i18next';
import { useGetGradesRequestQuery } from '@Redux/services/LookupApi';
import { LinkingRequestProps } from '@Pages/LinkingRequestDetails/types';

const AboutStudent: FC<LinkingRequestProps> = ({
    manageFieldPropFactory,
    selectFieldPropFactory,
    textFieldPropFactory,
    schools,
    handleGradeChange,
}) => {
    const [t] = useTranslation();
    const { data: { data: grades = [] } = {} } = useGetGradesRequestQuery({ searchText: '' });

    return (
        <Grid
            column={{ md: 2, sm: 1 }}
            columnGap={10}
            gridAutoFlow='dense'
            templateColumns={{
                base: 'repeat(1, 1fr)',
                md: 'repeat(2, 1fr)',
            }}
            rowGap={4}
            templateRows='auto'
            mb={6}
        >
            <TextInput
                {...textFieldPropFactory(t('LinkingRequestDetails.firstName'), ['firstName'])}
                {...manageFieldPropFactory<ManageTextProps>(['firstName'], 'onChangeText', 'value')}
            />
            <TextInput
                {...textFieldPropFactory(t('LinkingRequestDetails.lastName'), ['lastName'])}
                {...manageFieldPropFactory<ManageTextProps>(['lastName'], 'onChangeText', 'value')}
            />

            <SelectInput
                {...selectFieldPropFactory(t('LinkingRequestDetails.grade'), ['grade'])}
                {...manageFieldPropFactory<ManageTextProps>(['grade'], 'onChangeValue', 'value')}
                options={grades}
                onChange={(e) => {
                    handleGradeChange(e.target.value);
                }}
            />
            <SelectInput
                {...selectFieldPropFactory(t('LinkingRequestDetails.school'), ['schoolId'])}
                {...manageFieldPropFactory<ManageTextProps>(['schoolId'], 'onChangeValue', 'value')}
                options={schools}
            />

            <TextInput
                {...textFieldPropFactory(t('LinkingRequestDetails.studentNumber'), ['studentNumber'])}
                {...manageFieldPropFactory<ManageTextProps>(['studentNumber'], 'onChangeText', 'value')}
            />
        </Grid>
    );
};

export default AboutStudent;
