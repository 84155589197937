import React from 'react';
import { FormComponentProps, ManageTextProps, ManageSwitchInputProps } from '@Hooks';
import { useTranslation } from 'react-i18next';
import { Grid, Box } from '@chakra-ui/react';
import SelectInput from '@Components/SelectInput';
import TextInput from '@Components/TextInput';
import Switch from '@Components/Switch';
import { BasicLookup } from '@Redux/services/LookupApi/types';
import { formatPhoneNumber, removeSpecialCharacters } from '@Pages/RegistrationDetails/helpers';

interface ContactDetailsProps extends FormComponentProps {
    relationships: BasicLookup[];
    languages: BasicLookup[];
    contactIndex: number;
}

const Contact: React.FC<ContactDetailsProps> = ({
    manageFieldPropFactory,
    selectFieldPropFactory,
    textFieldPropFactory,
    switchFieldPropFactory,
    relationships,
    languages,
    contactIndex,
}) => {
    const [t] = useTranslation();
    const propIndex = `emergencyContact${contactIndex}`;

    return (
        <>
            <Grid
                column={{ md: 2, sm: 1 }}
                columnGap={10}
                gridAutoFlow='dense'
                templateColumns={{
                    base: 'repeat(1, 1fr)',
                    md: 'repeat(2, 1fr)',
                }}
                rowGap={4}
                templateRows='auto'
                mb={6}
            >
                <SelectInput
                    {...selectFieldPropFactory(t('RegistrationDetails.contact.contact.relationshipToStudent'), [
                        'emergencyContactInformation',
                        propIndex,
                        'relationshipToStudent',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['emergencyContactInformation', propIndex, 'relationshipToStudent'],
                        'onChangeValue',
                        'value'
                    )}
                    options={relationships}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.contact.contact.primaryPhone'), [
                        'emergencyContactInformation',
                        propIndex,
                        'primaryPhoneNumber',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['emergencyContactInformation', propIndex, 'primaryPhoneNumber'],
                        'onChangeText',
                        'value',
                        formatPhoneNumber
                    )}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.contact.contact.workPhone'), [
                        'emergencyContactInformation',
                        propIndex,
                        'workPhoneNumber',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['emergencyContactInformation', propIndex, 'workPhoneNumber'],
                        'onChangeText',
                        'value',
                        formatPhoneNumber
                    )}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.contact.contact.workExtension'), [
                        'emergencyContactInformation',
                        propIndex,
                        'workExtension',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['emergencyContactInformation', propIndex, 'workExtension'],
                        'onChangeText',
                        'value',
                        removeSpecialCharacters
                    )}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.contact.contact.mobilePhone'), [
                        'emergencyContactInformation',
                        propIndex,
                        'mobilePhoneNumber',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['emergencyContactInformation', propIndex, 'mobilePhoneNumber'],
                        'onChangeText',
                        'value',
                        formatPhoneNumber
                    )}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.contact.contact.firstName'), [
                        'emergencyContactInformation',
                        propIndex,
                        'firstName',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['emergencyContactInformation', propIndex, 'firstName'],
                        'onChangeText',
                        'value',
                        removeSpecialCharacters
                    )}
                    maxLength={29}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.contact.contact.lastName'), [
                        'emergencyContactInformation',
                        propIndex,
                        'surname',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['emergencyContactInformation', propIndex, 'surname'],
                        'onChangeText',
                        'value',
                        removeSpecialCharacters
                    )}
                    maxLength={29}
                />

                <SelectInput
                    {...selectFieldPropFactory(t('RegistrationDetails.contact.contact.languageSpoken'), [
                        'emergencyContactInformation',
                        propIndex,
                        'language',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['emergencyContactInformation', propIndex, 'language'],
                        'onChangeValue',
                        'value'
                    )}
                    options={languages}
                />

                <Box pb={4}>
                    <Switch
                        {...switchFieldPropFactory(t('RegistrationDetails.contact.contact.canPickUpChild'), [
                            'emergencyContactInformation',
                            propIndex,
                            'canPickUpChild',
                        ])}
                        {...manageFieldPropFactory<ManageSwitchInputProps>(
                            ['emergencyContactInformation', propIndex, 'canPickUpChild'],
                            'onChangeSwitch',
                            'isChecked'
                        )}
                    />
                </Box>
            </Grid>
        </>
    );
};

export default Contact;
