import FormHeader from '@Components/FormHeader';
import React from 'react';
import InnerFormHeader from '../InnerFormHeader';
import { FormComponentProps, ManageTextProps, ManageSwitchInputProps } from '@Hooks';
import { useTranslation } from 'react-i18next';
import { Grid, Box } from '@chakra-ui/react';
import SelectInput from '@Components/SelectInput';
import TextInput from '@Components/TextInput';
import Switch from '@Components/Switch';
import { useGetLanguagesRequestQuery, useGetRelationshipsRequestQuery } from '@Redux/services/LookupApi';
import SvgGuardianContact from '@Icon/GuardianContact';
import { format } from 'path';
import { formatPhoneNumber, removeSpecialCharacters } from '@Pages/RegistrationDetails/helpers';

const GuardianContactDetails: React.FC<FormComponentProps> = ({
    id,
    manageFieldPropFactory,
    selectFieldPropFactory,
    textFieldPropFactory,
    switchFieldPropFactory,
}) => {
    const [t] = useTranslation();

    //get relationships from redux
    const { data: { data: relationships = [] } = {} } = useGetRelationshipsRequestQuery({ searchText: '' });
    const { data: { data: languages = [] } = {} } = useGetLanguagesRequestQuery({ searchText: '' });

    return (
        <FormHeader
            id={id}
            header={
                <InnerFormHeader
                    divider={true}
                    title={t('RegistrationDetails.guardianContactDetails')}
                    icon={<SvgGuardianContact />}
                />
            }
            label={''}
        >
            <Grid
                column={{ md: 2, sm: 1 }}
                columnGap={10}
                gridAutoFlow='dense'
                templateColumns={{
                    base: 'repeat(1, 1fr)',
                    md: 'repeat(2, 1fr)',
                }}
                rowGap={4}
                templateRows='auto'
                mb={6}
            >
                <Box gridColumnStart={1}>
                    <Switch
                        {...switchFieldPropFactory(t('RegistrationDetails.guardianContactDetails.emergencyContact'), [
                            'guardianContactDetailsEmergencyContact',
                        ])}
                        {...manageFieldPropFactory<ManageSwitchInputProps>(
                            ['emergencyContactInformation', 'isParentEmergencyContact'],
                            'onChangeSwitch',
                            'isChecked'
                        )}
                    />
                </Box>

                <Box gridColumnStart={2}></Box>

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.guardianContactDetails.emailAddress'), [
                        'emergencyContactInformation',
                        'parentEmailAddress',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['emergencyContactInformation', 'parentEmailAddress'],
                        'onChangeText',
                        'value',
                        removeSpecialCharacters
                    )}
                    col={0}
                    isLocked={true}
                />

                <SelectInput
                    {...selectFieldPropFactory(t('RegistrationDetails.guardianContactDetails.relationshipToStudent'), [
                        'emergencyContactInformation',
                        'parent',
                        'relationshipToStudent',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['emergencyContactInformation', 'parent', 'relationshipToStudent'],
                        'onChangeValue',
                        'value'
                    )}
                    options={relationships}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.guardianContactDetails.firstName'), [
                        'emergencyContactInformation',
                        'parent',
                        'firstName',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['emergencyContactInformation', 'parent', 'firstName'],
                        'onChangeText',
                        'value',
                        removeSpecialCharacters
                    )}
                    isLocked={true}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.guardianContactDetails.lastName'), [
                        'emergencyContactInformation',
                        'parent',
                        'surname',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['emergencyContactInformation', 'parent', 'surname'],
                        'onChangeText',
                        'value',
                        removeSpecialCharacters
                    )}
                    isLocked={true}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.guardianContactDetails.primaryPhoneNumber'), [
                        'emergencyContactInformation',
                        'parent',
                        'primaryPhoneNumber',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['emergencyContactInformation', 'parent', 'primaryPhoneNumber'],
                        'onChangeText',
                        'value',
                        formatPhoneNumber
                    )}
                    isLocked={true}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.guardianContactDetails.mobilePhoneNumber'), [
                        'emergencyContactInformation',
                        'parent',
                        'mobilePhoneNumber',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['emergencyContactInformation', 'parent', 'mobilePhoneNumber'],
                        'onChangeText',
                        'value',
                        formatPhoneNumber
                    )}
                    isLocked={true}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.guardianContactDetails.workPhoneNumber'), [
                        'emergencyContactInformation',
                        'parent',
                        'workPhoneNumber',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['emergencyContactInformation', 'parent', 'workPhoneNumber'],
                        'onChangeText',
                        'value',
                        formatPhoneNumber
                    )}
                    isLocked={true}
                />
                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.guardianContactDetails.workExtention'), [
                        'emergencyContactInformation',
                        'parent',
                        'workExtention',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['emergencyContactInformation', 'parent', 'workExtention'],
                        'onChangeText',
                        'value',
                        formatPhoneNumber
                    )}
                    isLocked={true}
                />

                <SelectInput
                    {...selectFieldPropFactory(t('RegistrationDetails.guardianContactDetails.languageSpoken'), [
                        'emergencyContactInformation',
                        'parent',
                        'language',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['emergencyContactInformation', 'parent', 'language'],
                        'onChangeValue',
                        'value'
                    )}
                    options={languages}
                    isLocked={true}
                />

                <Switch
                    {...switchFieldPropFactory(t('RegistrationDetails.guardianContactDetails.custodyOfStudent'), [
                        'emergencyContactInformation',
                        'hasCustody',
                    ])}
                    {...manageFieldPropFactory<ManageSwitchInputProps>(
                        ['emergencyContactInformation', 'hasCustody'],
                        'onChangeSwitch',
                        'isChecked'
                    )}
                />
                <Switch
                    {...switchFieldPropFactory(t('RegistrationDetails.guardianContactDetails.courtOrderProvided'), [
                        'emergencyContactInformation',
                        'courtOrderProvided',
                    ])}
                    {...manageFieldPropFactory<ManageSwitchInputProps>(
                        ['emergencyContactInformation', 'courtOrderProvided'],
                        'onChangeSwitch',
                        'isChecked'
                    )}
                />
                <Switch
                    {...switchFieldPropFactory(t('RegistrationDetails.guardianContactDetails.copyOfCorrespondence'), [
                        'emergencyContactInformation',
                        'hasCopyOfCorespondence',
                    ])}
                    {...manageFieldPropFactory<ManageSwitchInputProps>(
                        ['emergencyContactInformation', 'hasCopyOfCorespondence'],
                        'onChangeSwitch',
                        'isChecked'
                    )}
                />
                <Switch
                    {...switchFieldPropFactory(t('RegistrationDetails.guardianContactDetails.canPickUpChild'), [
                        'emergencyContactInformation',
                        'parent',
                        'canPickUpChild',
                    ])}
                    {...manageFieldPropFactory<ManageSwitchInputProps>(
                        ['emergencyContactInformation', 'parent', 'canPickUpChild'],
                        'onChangeSwitch',
                        'isChecked'
                    )}
                />
            </Grid>
        </FormHeader>
    );
};

export default GuardianContactDetails;
