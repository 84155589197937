import { LinkingRequestStatus } from 'src/constants';

export const links = ['all', 'submitted', 'approved', 'deleted', 'rejected'] as const;

export type LinkingRequestStatusParam = (typeof links)[number];

export type SortByProps = { id: string; desc: boolean };

export const getStatusCode = (e: LinkingRequestStatusParam) => {
    switch (e) {
        case 'all':
            return [];
        case 'submitted':
            return [LinkingRequestStatus.Submitted];
        case 'approved':
            return [LinkingRequestStatus.Approved];
        case 'deleted':
            return [LinkingRequestStatus.Deleted];
        case 'rejected':
            return [LinkingRequestStatus.Rejected];
    }
};

export const getStatusByCode = (e: number) => {
    switch (e) {
        case LinkingRequestStatus.Submitted:
            return 'submitted';
        case LinkingRequestStatus.Approved:
            return 'approved';
        case LinkingRequestStatus.Deleted:
            return 'deleted';
        case LinkingRequestStatus.Rejected:
            return 'rejected';
    }
};

export const getSortByField = (s: SortByProps) => {
    let sBy = s;
    switch (s?.id) {
        case 'LinkingRequestStatus':
            sBy = { ...s, id: 'status' };
            break;
        case 'StudentName':
            sBy = { ...s, id: 'StudentName' };
            break;
        case 'GuardianName':
            sBy = { ...s, id: 'GuardianName' };
            break;
        case 'GuardianPhoneNumber':
            sBy = { ...s, id: 'GuardianPhoneNumber' };
            break;
        case 'GuardianEmail':
            sBy = { ...s, id: 'GuardianEmail' };
            break;
        case 'CreatedOn':
            sBy = { ...s, id: 'CreatedOn' };
            break;
    }
    return sBy;
};
