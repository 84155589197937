import { useDebounce } from '@Hooks';
import { Box, Flex, HStack, Text, useDisclosure } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    DATE_FORMAT,
    DATE_TIME_FORMAT,
    DEFAULT_PAGE_SIZE,
    EXCEL_FILE_CONTENT_TYPE,
    FIVE_MINUTES_MS,
    MFRStatusCodeEnum,
} from 'src/constants';
import {
    useExportLateAndAbsenceNotesMutation,
    useGetLateAbsenceNotesMutation,
    useGetNewMFRRecordsAmountMutation,
} from '@Redux/services/Absence';
import { PagedResult } from '@Redux/services/commonTypes';
import { Search } from '@Components/Search';
import { GhostIcon } from '@Components/Button';
import { Export, Refresh } from '@Icon';
import Table, { Column } from '@Components/Table';
import { Pagination } from '@Components';
import DateCell from '@Components/DateCell';
import ActionCell from '@Pages/EventDetails/components/CostItems/components/ActionCell';
import { LateAbsenceFilters, TableFilters } from './components/TableFilters';
import { downloadFile, parseError, parseWarning } from '@Utilities';
import { useToast } from '@Hooks/useToast';
import { format } from 'date-fns';
import { IconWithBadge } from '@Components/IconWithBadge';
import MfrDetailsDrawer from './components/MfrDetailsDrawer';
import { NoteTypeCell } from './components/NoteTypeCell';
import { isEmpty, isNull, isUndefined } from 'lodash';
import { CellProps } from 'react-table';
import { LateAbsenceNote } from '@Redux/services/Absence/types';

export type SortByProps = { id: string; desc: boolean };

const defaultFilters = {
    ownerId: null,
    statusReason: MFRStatusCodeEnum.New,
    grade: null,
    isLate: true,
    dateRange: [new Date(), new Date()],
    submittedDate: null,
};

export const LateAbsence = () => {
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [selectedAbsenceId, setSelectedAbsenceId] = useState<string>();
    const { t } = useTranslation();
    const mainCotainerRef = useRef<HTMLDivElement>(null);

    const defaultSortOrder = { id: 'startDate', desc: true };

    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [sortBy, setSortBy] = useState<SortByProps>(defaultSortOrder);

    const [filters, setFilters] = useState<LateAbsenceFilters>(defaultFilters);

    const [initialized, setInitialized] = useState(false);

    const toast = useToast();

    const [lastRefreshed, setLastRefreshed] = useState<Date>();

    const [
        GetLateAbsenceNotes,
        { isLoading, data: { data: lateAbsenceData = [], pagedResult = {} as PagedResult } = {} },
    ] = useGetLateAbsenceNotesMutation();

    const [exportLateAndAbsenceNotes] = useExportLateAndAbsenceNotesMutation();

    const getLateAbsences = (
        sortBy: SortByProps,
        currentPage: number,
        searchQuery: string,
        filters: LateAbsenceFilters
    ) => {
        const obj = {
            sortBy: sortBy?.id,
            isDesc: sortBy?.desc,
            currentPage: currentPage,
            pageSize: pageSize,
            searchText: searchQuery,
            startDate: filters?.dateRange?.[0] ?? null,
            endDate: filters?.dateRange?.[1] ?? null,
            submittedDate: new Date(
                Date.UTC(
                    filters.submittedDate?.getFullYear(),
                    filters.submittedDate?.getMonth(),
                    filters.submittedDate?.getDate()
                )
            ),
            grade: filters.grade === '' ? null : filters.grade,
            ownerId: filters.ownerId === '' ? null : filters.ownerId,
            statusReason: filters.statusReason?.toString() == '' ? null : filters.statusReason,
            validToday: filters.validToday,
            isLate: filters.isLate,
        };

        const now = new Date();

        GetLateAbsenceNotes(obj)
            .unwrap()
            .catch((e) => parseError(toast, e))
            .finally(() => {
                reset();
                setLastRefreshed(now);
            });
    };

    const [checkNew, { data: { data: newEntries = undefined } = {}, reset }] = useGetNewMFRRecordsAmountMutation();

    useEffect(() => {
        const checkNewEntriesInterval = setInterval(
            () => {
                checkNew({ lastUpdatedDate: lastRefreshed ?? new Date() });
            },
            FIVE_MINUTES_MS,
            [lastRefreshed]
        );

        return () => clearInterval(checkNewEntriesInterval);
    }, [lastRefreshed]);

    const debouncedResults = useDebounce(() => {
        getLateAbsences(sortBy, currentPage, searchQuery, filters);
    });

    useEffect(() => {
        debouncedResults();
    }, [searchQuery, filters]);

    useEffect(() => {
        if (!initialized) return;

        getLateAbsences(sortBy, currentPage, searchQuery, filters);
    }, [currentPage, sortBy, pageSize]);

    useEffect(() => {
        setTimeout(() => {
            setInitialized(true);
        }, 500);
    }, []);

    const onSortChange = (sortBy) => {
        if (sortBy[0]) {
            setSortBy(sortBy[0]);
        }
    };

    const handleFilterChange = (key: string) => (value) => {
        setCurrentPage(1);
        setFilters({ ...filters, [key]: value });
    };

    const handleFilterReset = (key: string, value: []) => {
        setCurrentPage(1);
        setFilters({ ...filters, [key]: value });
    };

    const handleReset = () => {
        setCurrentPage(1);
        setFilters({
            ownerId: null,
            statusReason: null,
            grade: null,
            dateRange: [],
            submittedDate: null,
            isLate: null,
        });
    };

    // Resetting pagination and filtering when searching
    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

    const handlePageSizeChange = (newPageSize: number) => {
        setCurrentPage(1);
        setPageSize(newPageSize);
    };

    const handleRefresh = () => {
        debouncedResults();
    };

    const isLate = (cell: CellProps<LateAbsenceNote>) =>
        !isEmpty(cell.original.lateReason) &&
        !isUndefined(cell.original.lateReason) &&
        !isNull(cell.original.lateReason);

    const defaultHeaders = [
        {
            Header: t('LateAbsenceNotes.student'),
            accessor: 'studentName',
            headerAlign: 'left',
        },
        {
            Header: t('LateAbsenceNotes.studentId'),
            accessor: 'studentPsId',
            sortType: 'datetime',
            headerAlign: 'left',
        },
        {
            Header: t('LateAbsenceNotes.status'),
            accessor: 'status',
            headerAlign: 'left',
        },
        {
            Header: t('LateAbsenceNotes.grade'),
            accessor: 'grade',
            headerAlign: 'left',
        },
        {
            Header: t('LateAbsenceNotes.noteType'),
            accessor: 'isLate',
            headerAlign: 'left',
            Cell: NoteTypeCell,
        },
        {
            Header: t('LateAbsenceNotes.reason'),
            accessor: 'reason',
            headerAlign: 'left',
        },
        {
            Header: t('LateAbsenceNotes.submittedBy'),
            accessor: 'submittedBy',
            headerAlign: 'left',
        },
        {
            Header: t('LateAbsenceNotes.absenceStart'),
            accessor: 'startDate',
            Cell: (cell: CellProps<LateAbsenceNote>) => DateCell(isLate(cell) ? DATE_FORMAT : DATE_TIME_FORMAT)(cell),
            headerAlign: 'left',
        },
        {
            Header: t('LateAbsenceNotes.absenceEnd'),
            accessor: 'endDate',
            Cell: (cell: CellProps<LateAbsenceNote>) => DateCell(isLate(cell) ? DATE_FORMAT : DATE_TIME_FORMAT)(cell),
            headerAlign: 'left',
        },
        {
            Header: t('LateAbsenceNotes.absenceSubmittedDate'),
            accessor: 'submittedDate',
            Cell: DateCell(DATE_TIME_FORMAT),
            headerAlign: 'left',
        },
        {
            Header: t('LateAbsenceNotes.homeSchool'),
            accessor: 'homeSchool',
            headerAlign: 'left',
        },
        {
            Header: t('LateAbsenceNotes.note'),
            accessor: 'note',
            headerAlign: 'left',
        },
        {
            Header: '',
            accessor: 'actions',
            disableSortBy: true,
            headerAlign: 'left',
            Cell: ActionCell,
        },
    ] as Column[];

    const handleEdit = (row) => {
        setSelectedAbsenceId(row.original.attendanceId);
        onOpen();
    };
    const handleExport = async () => {
        await exportLateAndAbsenceNotes({
            pageSize: 0,
            currentPage: 1,
            sortBy: sortBy.id,
            isDesc: sortBy.desc,
        })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                downloadFile(EXCEL_FILE_CONTENT_TYPE, response.data.fileContents, response.data.fileName);
            })
            .catch((err) => parseError(toast, err));
    };

    return (
        <>
            <Flex id='st' align={'stretch'} h='inherit'>
                <Flex ref={mainCotainerRef} py={0} pl={2} pr={2} overflowX={'hidden'} gap={4} w={'full'} h='inherit'>
                    <Flex
                        overflowX={'hidden'}
                        borderRadius='20px 0 0 0'
                        borderWidth='2px 2px 2px 2px'
                        borderStyle='solid'
                        borderColor='border-primary'
                        height={'full'}
                        w={'full'}
                        align={'stretch'}
                        direction={'column'}
                    >
                        <Box>
                            <Flex
                                justify={'space-between'}
                                align={'center'}
                                p={6}
                                flexDirection={{ base: 'column', md: 'row' }}
                            >
                                <Flex flexDirection={'column'}>
                                    <Text textStyle={'md-bold'} pb={{ base: 2, md: 0 }}>
                                        {t('LateAbsenceNotes.title.all')}
                                    </Text>
                                    {lastRefreshed && (
                                        <Text textStyle={'sm-medium'} pb={{ base: 2, md: 0 }}>
                                            {t('Table.lastUpdatedOn') + format(lastRefreshed, DATE_TIME_FORMAT)}
                                        </Text>
                                    )}
                                </Flex>
                                <HStack spacing={5}>
                                    <Box maxW={224} minW={224}>
                                        <Search iconPosition='left' query={searchQuery} onChange={handleSearch} />
                                    </Box>

                                    <IconWithBadge
                                        badgeText={newEntries?.amount > 0 ? newEntries.amount.toString() : ''}
                                    >
                                        <GhostIcon
                                            aria-label='refresh'
                                            size={'sm'}
                                            borderRadius='sm'
                                            title={t('LateAbsenceNotes.refreshTable')}
                                            icon={<Refresh width={20} height={20} fill='text-primary' />}
                                            onClick={handleRefresh}
                                        />
                                    </IconWithBadge>
                                    <GhostIcon
                                        aria-label='export'
                                        size={'sm'}
                                        borderRadius='sm'
                                        title={t('LateAbsenceNotes.exportTable')}
                                        icon={<Export width={20} height={20} />}
                                        onClick={handleExport}
                                    />
                                </HStack>
                            </Flex>
                        </Box>
                        <Flex position='relative' flex='auto'>
                            <Table
                                manualPagination={true}
                                manualSortBy={true}
                                columns={defaultHeaders}
                                filter={
                                    <TableFilters
                                        onChange={handleFilterChange}
                                        onResetField={handleFilterReset}
                                        onReset={handleReset}
                                        filters={filters}
                                        showAlert={true}
                                    />
                                }
                                rawData={isLoading ? [] : lateAbsenceData}
                                emptyMessage={t('LateAbsenceNotes.noData')}
                                isLoading={isLoading}
                                onSortChange={onSortChange}
                                onEditHandler={handleEdit}
                                initialSortBy={sortBy ? [sortBy] : []}
                                tableWidth={{ 'table-layout': 'auto', width: '100%' }}
                                containerStyle={{ borderLeftWidth: 0, borderRightWidth: 0, borderRadius: 0 }}
                                stickyHeader
                            />
                        </Flex>

                        {!pagedResult?.pageCount ? null : (
                            <Box p={2}>
                                <Pagination
                                    currentPage={currentPage}
                                    onPageChange={setCurrentPage}
                                    totalPages={pagedResult?.pageCount}
                                    totalResults={pagedResult?.rowCount}
                                    onPageSizeChange={handlePageSizeChange}
                                    pageSize={pageSize}
                                />
                            </Box>
                        )}
                    </Flex>
                </Flex>
            </Flex>
            {selectedAbsenceId && isOpen && (
                <MfrDetailsDrawer isOpen={isOpen} onClose={onClose} absenceId={selectedAbsenceId} />
            )}
        </>
    );
};
