import { LinkRequestInformation } from '@Redux/services/LinkingRequest/types';
import { ValidatorType } from '@Utilities';
import { TFunction } from 'react-i18next';
import type { PartialDeep } from 'type-fest';

export function Validator(
    linkRequest: PartialDeep<LinkRequestInformation>,
    t: TFunction,
    saveOnly?: boolean
): ValidatorType[] {
    const saveRules = [
        {
            accessorPath: ['firstName'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
        },
        {
            accessorPath: ['lastName'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
        },
        {
            accessorPath: ['schoolId'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
        },
        {
            accessorPath: ['relationshipToStudent'],
            validator: Boolean,
            message: t('RegistrationDetails.fieldRequired'),
            required: true,
        },
    ];
    return saveOnly
        ? saveRules
        : [
              ...saveRules,
              {
                  accessorPath: ['studentNumber'],
                  validator: Boolean,
                  message: t('RegistrationDetails.fieldRequired'),
                  required: true,
              },
              {
                  accessorPath: ['parentPosition'],
                  validator: Boolean,
                  message: t('RegistrationDetails.fieldRequired'),
                  required: true,
              },
          ];
}
