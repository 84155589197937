import { Badge, Box } from '@chakra-ui/react';
import { useGetStatusForStudentLinkingsRequestQuery } from '@Redux/services/LookupApi';
import { BasicLookup } from '@Redux/services/LookupApi/types';
import { createLookupMap } from '@Utilities';
import React, { useMemo } from 'react';
import { LinkingRequestStatus } from 'src/constants';
interface IProps {
    value: number;
}

const StatusCell: React.FC<IProps> = ({ value }) => {
    const { data: { data: responseStatus = [] } = {} } = useGetStatusForStudentLinkingsRequestQuery({
        searchText: '',
    });
    const responseStatusMap = useMemo(() => createLookupMap<BasicLookup>(responseStatus), [responseStatus]);
    const getBadgeVariant = useMemo(() => {
        switch (value) {
            case LinkingRequestStatus.Approved:
                return 'success';
            case LinkingRequestStatus.Deleted:
                return 'subtle';
            case LinkingRequestStatus.Rejected:
                return 'pink';
            case LinkingRequestStatus.Submitted:
                return 'primary';
        }
    }, [value]);
    return (
        <Box display={'flex'}>
            <Badge marginX={'auto'} variant={getBadgeVariant}>
                {responseStatusMap[value] ? responseStatusMap[value].value : '--'}
            </Badge>
        </Box>
    );
};

export default StatusCell;
