import { TFunction } from 'react-i18next';
import { LinkRequestSidebarItem } from '../types';
import SvgAboutStudent from '@Icon/AboutStudent';
import SvgNotes from '@Icon/Notes';
import SvgGuardianContact from '@Icon/GuardianContact';

const sideBarData = (item: LinkRequestSidebarItem, t: TFunction) => ({
    groups: [
        {
            items: [
                {
                    label: t('LinkingRequestDetails.aboutStudent'),
                    id: '#aboutstudent',
                    icon: <SvgAboutStudent />,
                    active: item === LinkRequestSidebarItem.AboutStudent,
                },
                {
                    label: t('LinkingRequestDetails.guardianContactDetails.label'),
                    id: '#guardiancontactdetails',
                    icon: <SvgGuardianContact />,
                    active: item === LinkRequestSidebarItem.GuardianContactDetails,
                },
                {
                    label: t('LinkingRequestDetails.notes'),
                    id: '#notes',
                    icon: <SvgNotes />,
                    active: item === LinkRequestSidebarItem.Notes,
                },
            ],
        },
    ],
});

export { sideBarData };
