import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormComponentProps, ManageTextProps } from '@Hooks';
import { Box, Grid } from '@chakra-ui/react';
import SelectInput from '@Components/SelectInput';
import TextInput from '@Components/TextInput';
import { BasicLookup } from '@Redux/services/LookupApi/types';
import { formatPhoneNumber, formatPostalCode, removeSpecialCharacters } from '@Pages/RegistrationDetails/helpers';

interface PickUpDetailsProps extends FormComponentProps {
    cities: BasicLookup[];
}

const PickUpDetails: React.FC<PickUpDetailsProps> = ({
    manageFieldPropFactory,
    selectFieldPropFactory,
    textFieldPropFactory,
    cities,
}) => {
    const [t] = useTranslation();

    return (
        <>
            <Box pb={4}>
                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.busNeeds.reqTransportationPickUp.streetNumber'), [
                        'transportationInformation',
                        'pickUpAddress',
                        'streetName',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['transportationInformation', 'pickUpAddress', 'streetName'],
                        'onChangeText',
                        'value',
                        removeSpecialCharacters
                    )}
                    col={1}
                />
            </Box>
            <Grid
                column={{ md: 2, sm: 1 }}
                columnGap={10}
                gridAutoFlow='dense'
                templateColumns={{
                    base: 'repeat(1, 1fr)',
                    md: 'repeat(2, 1fr)',
                }}
                rowGap={4}
                templateRows='auto'
                mb={6}
            >
                <TextInput
                    {...textFieldPropFactory(
                        t('RegistrationDetails.busNeeds.reqTransportationPickUp.apartmentNumber'),
                        ['transportationInformation', 'pickUpAddress', 'apartmentNumber']
                    )}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['transportationInformation', 'pickUpAddress', 'apartmentNumber'],
                        'onChangeText',
                        'value',
                        removeSpecialCharacters
                    )}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.busNeeds.reqTransportationPickUp.routeNumber'), [
                        'transportationInformation',
                        'pickUpAddress',
                        'rrNumber',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['transportationInformation', 'pickUpAddress', 'rrNumber'],
                        'onChangeText',
                        'value',
                        removeSpecialCharacters
                    )}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.busNeeds.reqTransportationPickUp.postalCode'), [
                        'transportationInformation',
                        'pickUpAddress',
                        'postalCode',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['transportationInformation', 'pickUpAddress', 'postalCode'],
                        'onChangeText',
                        'value',
                        formatPostalCode
                    )}
                />

                <SelectInput
                    {...selectFieldPropFactory(t('RegistrationDetails.busNeeds.reqTransportationPickUp.city'), [
                        'transportationInformation',
                        'pickUpAddress',
                        'city',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['transportationInformation', 'pickUpAddress', 'city'],
                        'onChangeValue',
                        'value'
                    )}
                    options={cities}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.busNeeds.reqTransportationPickUp.contactName'), [
                        'transportationInformation',
                        'pickUpContactName',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['transportationInformation', 'pickUpContactName'],
                        'onChangeText',
                        'value',
                        removeSpecialCharacters
                    )}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.busNeeds.reqTransportationPickUp.contactNumber'), [
                        'transportationInformation',
                        'pickUpContactPhone',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['transportationInformation', 'pickUpContactPhone'],
                        'onChangeText',
                        'value',
                        formatPhoneNumber
                    )}
                />
            </Grid>
        </>
    );
};

export default PickUpDetails;
