import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormComponentProps, ManageTextProps } from '@Hooks';
import { Box, Grid } from '@chakra-ui/react';
import SelectInput from '@Components/SelectInput';
import TextInput from '@Components/TextInput';
import { BasicLookup } from '@Redux/services/LookupApi/types';
import { RegistrationInformation } from '@Redux/services/Registration/types';
import { formatPostalCode, removeSpecialCharacters } from '@Pages/RegistrationDetails/helpers';

interface ParentAddressDetailsProps extends FormComponentProps {
    provinces: BasicLookup[];
    countries: BasicLookup[];
    registrationInformation: RegistrationInformation;
}

const ParentAddressDetails: React.FC<ParentAddressDetailsProps> = ({
    manageFieldPropFactory,
    selectFieldPropFactory,
    textFieldPropFactory,
    setIsDirty,
    provinces,
    countries,
    registrationInformation,
}) => {
    const [t] = useTranslation();
    const [provinceDisabled, setProvinceDisabled] = useState(false);
    const canadaKey = useMemo(() => countries.find((country) => country.value === 'Canada')?.key, [countries]);

    const provinceParams = {
        ...manageFieldPropFactory<ManageTextProps>(
            ['addressInformation', 'parentAddress', 'province'],
            'onChangeValue',
            'value'
        ),
    };

    useEffect(() => {
        if (registrationInformation.addressInformation?.parentAddress?.country !== canadaKey) {
            provinceParams['onChangeValue'](0);
            setProvinceDisabled(true);
        } else {
            setProvinceDisabled(false);
        }
        setIsDirty(false);
    }, [registrationInformation.addressInformation?.parentAddress?.country]);

    return (
        <>
            <Box pb={4}>
                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.homeAddress.parentAddress.streetNumber'), [
                        'addressInformation',
                        'parentAddress',
                        'streetName',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['addressInformation', 'parentAddress', 'streetName'],
                        'onChangeText',
                        'value',
                        removeSpecialCharacters
                    )}
                    col={1}
                />
            </Box>
            <Grid
                column={{ md: 3, sm: 1 }}
                columnGap={10}
                gridAutoFlow='dense'
                templateColumns={{
                    base: 'repeat(1, 1fr)',
                    md: 'repeat(3, 1fr)',
                }}
                rowGap={4}
                templateRows='auto'
                mb={6}
            >
                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.homeAddress.parentAddress.apartmentNumber'), [
                        'addressInformation',
                        'parentAddress',
                        'apartmentNumber',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['addressInformation', 'parentAddress', 'apartmentNumber'],
                        'onChangeText',
                        'value',
                        removeSpecialCharacters
                    )}
                    labelProps={{ flexBasis: '55%' }}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.homeAddress.parentAddress.routeNumber'), [
                        'addressInformation',
                        'parentAddress',
                        'rrNumber',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['addressInformation', 'parentAddress', 'rrNumber'],
                        'onChangeText',
                        'value',
                        removeSpecialCharacters
                    )}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.homeAddress.parentAddress.poBox'), [
                        'addressInformation',
                        'parentAddress',
                        'poBox',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['addressInformation', 'parentAddress', 'poBox'],
                        'onChangeText',
                        'value',
                        removeSpecialCharacters
                    )}
                />
            </Grid>

            <Grid
                column={{ md: 2, sm: 1 }}
                columnGap={10}
                gridAutoFlow='dense'
                templateColumns={{
                    base: 'repeat(1, 1fr)',
                    md: 'repeat(2, 1fr)',
                }}
                rowGap={4}
                templateRows='auto'
                mb={6}
            >
                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.homeAddress.parentAddress.postalCode'), [
                        'addressInformation',
                        'parentAddress',
                        'postalCode',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['addressInformation', 'parentAddress', 'postalCode'],
                        'onChangeText',
                        'value',
                        formatPostalCode
                    )}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.homeAddress.parentAddress.city'), [
                        'addressInformation',
                        'parentAddress',
                        'cityFreeForm',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['addressInformation', 'parentAddress', 'cityFreeForm'],
                        'onChangeText',
                        'value',
                        removeSpecialCharacters
                    )}
                />
                <SelectInput
                    {...selectFieldPropFactory(t('RegistrationDetails.homeAddress.parentAddress.province'), [
                        'addressInformation',
                        'parentAddress',
                        'province',
                    ])}
                    {...provinceParams}
                    options={provinces}
                    isLocked={provinceDisabled}
                />

                <SelectInput
                    {...selectFieldPropFactory(t('RegistrationDetails.homeAddress.parentAddress.country'), [
                        'addressInformation',
                        'parentAddress',
                        'city',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['addressInformation', 'parentAddress', 'country'],
                        'onChangeValue',
                        'value'
                    )}
                    options={countries}
                />
            </Grid>
        </>
    );
};

export default ParentAddressDetails;
