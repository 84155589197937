import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormComponentProps, ManageTextProps } from '@Hooks';
import { Box, Grid } from '@chakra-ui/react';
import SelectInput from '@Components/SelectInput';
import TextInput from '@Components/TextInput';
import { BasicLookup } from '@Redux/services/LookupApi/types';
import { formatPhoneNumber, formatPostalCode, removeSpecialCharacters } from '@Pages/RegistrationDetails/helpers';

interface DropOffDetailsProps extends FormComponentProps {
    cities: BasicLookup[];
}

const DropOffDetails: React.FC<DropOffDetailsProps> = ({
    manageFieldPropFactory,
    selectFieldPropFactory,
    textFieldPropFactory,
    cities,
}) => {
    const [t] = useTranslation();

    return (
        <>
            <Box pb={4}>
                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.busNeeds.reqTransportationDropOff.streetNumber'), [
                        'transportationInformation',
                        'dropOffAddress',
                        'streetName',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['transportationInformation', 'dropOffAddress', 'streetName'],
                        'onChangeText',
                        'value',
                        removeSpecialCharacters
                    )}
                    col={1}
                />
            </Box>
            <Grid
                column={{ md: 2, sm: 1 }}
                columnGap={10}
                gridAutoFlow='dense'
                templateColumns={{
                    base: 'repeat(1, 1fr)',
                    md: 'repeat(2, 1fr)',
                }}
                rowGap={4}
                templateRows='auto'
                mb={6}
            >
                <TextInput
                    {...textFieldPropFactory(
                        t('RegistrationDetails.busNeeds.reqTransportationDropOff.apartmentNumber'),
                        ['transportationInformation', 'dropOffAddress', 'apartmentNumber']
                    )}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['transportationInformation', 'dropOffAddress', 'apartmentNumber'],
                        'onChangeText',
                        'value',
                        removeSpecialCharacters
                    )}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.busNeeds.reqTransportationDropOff.routeNumber'), [
                        'transportationInformation',
                        'dropOffAddress',
                        'rrNumber',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['transportationInformation', 'dropOffAddress', 'rrNumber'],
                        'onChangeText',
                        'value',
                        removeSpecialCharacters
                    )}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.busNeeds.reqTransportationDropOff.postalCode'), [
                        'transportationInformation',
                        'dropOffAddress',
                        'postalCode',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['transportationInformation', 'dropOffAddress', 'postalCode'],
                        'onChangeText',
                        'value',
                        formatPostalCode
                    )}
                />

                <SelectInput
                    {...selectFieldPropFactory(t('RegistrationDetails.busNeeds.reqTransportationDropOff.city'), [
                        'transportationInformation',
                        'dropOffAddress',
                        'city',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['transportationInformation', 'dropOffAddress', 'city'],
                        'onChangeValue',
                        'value'
                    )}
                    options={cities}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.busNeeds.reqTransportationDropOff.contactName'), [
                        'transportationInformation',
                        'dropOffContactName',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['transportationInformation', 'dropOffContactName'],
                        'onChangeText',
                        'value',
                        removeSpecialCharacters
                    )}
                />

                <TextInput
                    {...textFieldPropFactory(t('RegistrationDetails.busNeeds.reqTransportationDropOff.contactNumber'), [
                        'transportationInformation',
                        'dropOffContactPhone',
                    ])}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['transportationInformation', 'dropOffContactPhone'],
                        'onChangeText',
                        'value',
                        formatPhoneNumber
                    )}
                    col={1}
                />
            </Grid>
        </>
    );
};

export default DropOffDetails;
